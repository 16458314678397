import { logger } from "@/libs/utils";
import { GlobalRef } from "@/src/api/GlobalRef";
import { Client, createInstance, enums } from "@optimizely/optimizely-sdk";
import fallbackOptimizelyDatafile from "@/src/services/optimizely/fallbackDatafile.json";
import { isProd } from "@/src/helpers/environment";
import { OPTIMIZELY_REFRESH_INTERVAL } from "@/src/services/optimizely/constants";


const optimizelyInstanceGlobal = new GlobalRef<Client>("optimizelyClient");
/***
 *
 * We are creating a single instance of optimizely using a global ref
 * Under the hood, optimizely automatically will autoupdate the
 * datafile on the specified refresh interval above, so that we do not have to
 * Creating one instance so we don't have to keep fetching the datafile over
 * and over.
 *
 ***/

export const setOptimizelyClient = () => {
  if (!optimizelyInstanceGlobal.value) {
    logger.debug("OPTIMIZELY: static Optimizely client initializing");
    const sdkKey = process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY;
    optimizelyInstanceGlobal.value = createInstance({
      sdkKey,
      datafile: fallbackOptimizelyDatafile,
      datafileOptions: {
        autoUpdate: true,
        updateInterval: OPTIMIZELY_REFRESH_INTERVAL,
      },
      logLevel: isProd() ? enums.LOG_LEVEL.ERROR : enums.LOG_LEVEL.INFO,
    }) as Client;
  }
};
setOptimizelyClient();

export const optimizelyInstance = optimizelyInstanceGlobal.value;
