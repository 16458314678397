import * as React from "react";
import type { SVGProps } from "react";
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.125 14.25A4.125 4.125 0 1 0 12 10.125M14.25 14.25h2M7.25 14.25h2.5M7.5 14.25a3.75 3.75 0 0 1 0-7.5M15.712 17.72 12 21.432 8.288 17.72M12 20.75V10"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 7.5a5.25 5.25 0 0 1 9.094-3.576"
    />
  </svg>
);
export default SvgDownload;
