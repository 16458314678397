import * as React from "react";
import type { SVGProps } from "react";
const SvgReceipt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M10.5 6.75H18M10.5 9.75H18M10.5 12.75h6M6 20.25h12c1.24 0 2.25-1.01 2.25-2.25V3.75h-12V18"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8.25 18c0 1.24-1.01 2.25-2.25 2.25S3.75 19.24 3.75 18v-5.25H6"
    />
  </svg>
);
export default SvgReceipt;
