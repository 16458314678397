import { Country } from "@/libs/geo/countries";

export type FooterItemType = {
  link?: string;
  title: string;
  key: FooterItems | string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (item: any) => void;
};

export enum FooterItems {
  LINK,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  COOKIE_SETTINGS,
  CONTACT_US,
  TERMS_OF_SALE,
  TERMS_OF_USE,
}

export type FooterProps = {
  className?: string;
  country?: Country;
  setOpenCookieBanner?: (boolean: boolean) => void;
};
