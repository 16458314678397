import { StatusBannerTypes } from "nvs-design-system/dist/StatusBanner";
import { useGlobalStatusBannerQuery } from "./useGlobalStatusBannerQuery";
import { useStatusBannerStorage } from "./useStatusBannerStorage";

export function useStatusBanner() {
  const { data } = useGlobalStatusBannerQuery();
  const statusBannerStorage = useStatusBannerStorage({
    id: data?.sys.publishedAt,
    initialIsDismissed: null,
  });

  // if the banner has been dismissed in local storage, ignore the fetched state
  const isVisible = statusBannerStorage.isDismissed ? false : data?.isVisible;

  return {
    message: data?.message as string,
    link: data?.link,
    linkCta: data?.linkCta,
    color: data?.color as StatusBannerTypes,
    setDismissed: statusBannerStorage.setDismissed,
    isVisible: isVisible as boolean,
  };
}
