import * as React from "react";
import type { SVGProps } from "react";
const SvgNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.776 8.338 18.26 3.843c1.172-.391 2.288.725 1.898 1.897l-4.495 13.485c-.448 1.34-2.331 1.376-2.827.05l-2.313-5.797-5.8-2.313c-1.323-.496-1.288-2.38.053-2.827Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavigation;
