import * as React from "react";
import type { SVGProps } from "react";
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M21 8.25H10M4.75 8.25H3" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7.5 6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3 15.75h10.75M18.75 15.75H21"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.5 13.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFilter;
