import { Country } from "@/libs/geo/countries";
import { CookiePreferences, User } from "@/libs/schema/user";
import _, { size, get } from "lodash";
import { supportedEmeaCountryCodes } from "nvs-constants";

export const isUserMFAed = (user: Partial<User>) => {
  return size(user.phoneNumbers) > 0;
};

export const PRIVACY_SETTINGS = {
  0: { allowPerformance: false, allowMarketing: false },
  1: { allowPerformance: true, allowMarketing: false },
  2: { allowPerformance: false, allowMarketing: true },
  3: { allowPerformance: true, allowMarketing: true },
};

export const convertToSQSettings = (setting: {
  allowMarketing?: boolean;
  allowPerformance?: boolean;
}) => {
  return _.findKey(PRIVACY_SETTINGS, setting);
};

export const SQ = (cookieSettings?: CookiePreferences) =>
  convertToSQSettings({
    allowMarketing: cookieSettings?.functional,
    allowPerformance: cookieSettings?.performance,
  });

export const isCountryASupportedEMEACountry = (country: Country | string) => {
  return !!get(supportedEmeaCountryCodes, country);
};
