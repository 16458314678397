import * as React from "react";
import type { SVGProps } from "react";
const SvgFlashlightOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.25 13v6.5c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25v-8.75"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M10.829 11.25h2.342a3 3 0 0 0 2.809-1.947l1.267-3.377a.5.5 0 0 0-.468-.676H7.22a.5.5 0 0 0-.468.676L8.02 9.303a3 3 0 0 0 2.809 1.947Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 15v1.5M11.94 3V0M9.65 2.98 8.15.38M14.35 2.98l1.5-2.6"
    />
  </svg>
);
export default SvgFlashlightOn;
