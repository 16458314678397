import axios from "axios";
import { CONTENTFUL_BASE_URL } from "./constants";

const GLOBAL_STATUS_BANNER_API = `${CONTENTFUL_BASE_URL}/global-status-banner/`;

export const makeGlobalStatusBannerApiCall = async () => {
  return await axios.get(GLOBAL_STATUS_BANNER_API).then(({ data }) => {
    return data.globalStatusBannerCollection.items[0];
  });
};
