import * as React from "react";
import type { SVGProps } from "react";
const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12 5.25H6c-1.24 0-2.25 1.01-2.25 2.25V18c0 1.24 1.01 2.25 2.25 2.25h10.5c1.24 0 2.25-1.01 2.25-2.25v-6M21.75 9V2.25H15M11.25 12.75l10.5-10.5"
    />
  </svg>
);
export default SvgExternalLink;
