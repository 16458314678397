import * as React from "react";
import type { SVGProps } from "react";
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.75 13.5v6.75h6.75M20.25 10.5V3.75H13.5"
    />
  </svg>
);
export default SvgExpand;
