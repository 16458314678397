import * as React from "react";
import type { SVGProps } from "react";
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 7.5v9c0 2.07-1.68 3.75-3.75 3.75"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.25 7.51a5.254 5.254 0 0 0-5.24-5.26c-2.9-.01-5.25 2.34-5.26 5.24"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.49 6.75c-2.07 0-3.75 1.68-3.75 3.75 0 2.07 1.68 3.75 3.75 3.75h2.26M14.25 14.25h2.24c2.07 0 3.75-1.68 3.75-3.75 0-2.07-1.68-3.75-3.75-3.75"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8.29 10.78 12 7.07l3.71 3.71"
    />
  </svg>
);
export default SvgUpload;
