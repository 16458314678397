import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import AppErrorBoundary from "./AppErrorBoundary";

const ReactQueryErrorFallback: React.FC<{
  children?: React.ReactNode;
  FallbackComponent?: React.FC<FallbackProps>;
}> = ({ children, FallbackComponent = AppErrorBoundary }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={(props) => <FallbackComponent {...props} />}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ReactQueryErrorFallback;
