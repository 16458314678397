import React from "react";

const ArrowSvg: React.FC<{ classes?: string }> = ({ classes = "" }) => {
  return (
    <svg
      className={classes}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title lang="en">Arrow</title>

      <path
        d="M6.63682 1L11.8184 1L17 1M17 1V11.3632M17 1L1 17"
        strokeWidth="2"
        stroke="currentColor"
      />
    </svg>
  );
};
export default ArrowSvg;
