import * as React from "react";
import { Title3 } from "./typography/components/Title";
import { TitleName } from "./typography/components/Title/types";
import { Body7 } from "./typography/components/Body";
import { BodyName } from "./typography/components/Body/types";
import clsx from "clsx";

type GeneralErrorProps = React.PropsWithChildren<{
  header?: React.ReactNode;
  className?: string;
}>;

const GeneralError: React.FC<GeneralErrorProps> = ({
  header,
  children,
  className,
}) => {
  return (
    <div
      className={clsx(
        "flex h-full flex-grow flex-col justify-center",
        className,
      )}
    >
      <ErrorComponent header={header}>{children}</ErrorComponent>
    </div>
  );
};

type ErrorComponentProps = GeneralErrorProps & { secondaryHeaderCopy?: string };

export const ErrorComponent: React.FC<ErrorComponentProps> = ({
  header,
  children,
  secondaryHeaderCopy,
}) => (
  <div className="flex-col ml md:items-center flex justify-center">
    <div
      className="md:max-w-lg max-w-sm mt-28 md:mt-0 mx-6 xs
    :mx-0 flex flex-col gap-6 mb-9"
    >
      {header ?? (
        <Title3
          md={TitleName.Title_1}
          className="basis-6 text-white font-medium"
          data-testid="general-error-header"
        >
          <span className="whitespace-nowrap">We found an </span>
          <br className="laptop:block" />
          error
        </Title3>
      )}
      <Body7 md={BodyName.Body_5} className="mb-3 text-white">
        {secondaryHeaderCopy ?? (
          <>
            We had an issue with your request. If you continue
            <br className="md:block hidden" /> experiencing issues, please try
            again later
          </>
        )}
      </Body7>
      {children}
    </div>
  </div>
);

export default GeneralError;
