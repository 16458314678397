import * as React from "react";
import type { SVGProps } from "react";
const SvgAddPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M20.25 16.5v-9A2.25 2.25 0 0 0 18 5.25H6A2.25 2.25 0 0 0 3.75 7.5v9A2.25 2.25 0 0 0 6 18.75h6M3.75 9.75h16.5M17.25 15v7.5M21 18.75h-7.5"
    />
  </svg>
);
export default SvgAddPayment;
