import * as React from "react";
import type { SVGProps } from "react";
const SvgReview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.5 9.5 5 5M19.5 9.5l-5 5M10.75 9.5 6.25 14 4.5 12.25"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 18.75H.75V5.25H12M12 5.25h11.25v13.5H12"
    />
  </svg>
);
export default SvgReview;
