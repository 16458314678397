export type CookiePreference = {
  required?: boolean;
  displayName: string;
  description: string;
  defaultChecked?: boolean;
  name: "functional" | "performance";
};
const cookieOptions: CookiePreference[] = [
  {
    name: "functional",
    displayName: "Functional",
    description: `These cookies are required for basic site functionality and therefore always enabled. These include cookies that allow you to be
    remembered as you explore the site within a single session or, if you request, from session to session. They help to make the
    bag and checkout process possible as well as assisting in security issues and confirming to regulations.`,
    required: true,
  },
  {
    name: "performance",
    displayName: "Performance",
    description: `These cookies allow us to improve the site’s functionality by tracking usage on the website. In some cases these cookies can
    improve the speed with which we can process your request as they allow us to remember site preferences that you’ve selected.
    De-selecting these cookies may result in poorly tailored recommendations and slow site performance.`,
  },
];

export const cookiePreferencesCopy = {
  buttonCTA: "Done",
  cookieOptions,
};

export const cookieFinePrint = `You can always change your preference by clicking ‘Cookie Settings’ at the bottom of the page.`;

export const buttonCTAsForMainCookieView = {
  moreInfo: "More Information",
  accept: "Yes, I Accept",
};

export const mainCookieCopy = `Nike asks you to accept cookies for performance purposes. To get more information or amend your preferences, click the
“More Information” button or visit “Cookie Settings” at the bottom of the website. To get more information about these
cookies and the processing of your personal data, check our Privacy Policy. Do you accept these cookies and the
processing of personal data involved?`;
