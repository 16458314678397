import * as React from "react";
import type { SVGProps } from "react";
const SvgThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.25 9c0-1.86.76-3.55 1.98-4.77A6.735 6.735 0 0 1 11 2.25h6.25c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-.75 1.75c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H16.5h1.75c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H16.5h1.75c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-7.68c-.38 0-.63.42-.43.75l1.73 3c.41.72.17 1.63-.55 2.05-.72.41-1.63.17-2.05-.55l-4.15-7.2A6.58 6.58 0 0 1 4.25 9Zm0 0c0-3.73 3.02-6.75 6.75-6.75"
    />
  </svg>
);
export default SvgThumbsDown;
