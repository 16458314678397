import * as React from "react";
import type { SVGProps } from "react";
const SvgMultimedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M13.5 20.25h-9c-1.24 0-2.25-1.01-2.25-2.25v-7.5c0-1.24 1.01-2.25 2.25-2.25h9c1.24 0 2.25 1.01 2.25 2.25V18c0 1.24-1.01 2.25-2.25 2.25Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8.25 8.25v-.76c0-1.24 1.01-2.25 2.25-2.25h9c1.24 0 2.25 1.01 2.25 2.25V15c0 1.24-1.01 2.25-2.25 2.25h-3.75M17.99 8.25h1.5M17.99 11.25h1.5M17.99 14.25h1.5M6.75 14.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM5.75 20.2 12 13.95l3.75 3.75"
    />
  </svg>
);
export default SvgMultimedia;
