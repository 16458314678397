import * as React from "react";
import type { SVGProps } from "react";
const SvgQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.5 10.5h6v-6h-6v6ZM4.5 19.5h6v-6h-6v6ZM13.5 10.5h6v-6h-6v6Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12.75 13.5h4.5M18.75 13.5h1.5M12.75 16.5h1.5M20.25 19.5H16.5v-3h3.75"
    />
  </svg>
);
export default SvgQrCode;
