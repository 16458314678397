import { ERROR_CODES } from "@/libs/error/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserUpdate } from "libs/schema/user";
import axiosWithCredentials from "../commons";
import { CURRENT_USER } from "./reactQueryCacheKeys";
import { CURRENT_USER_API } from "./user/common";

export const useUpdateCurrentUser = (
  onError?: (response: any) => void,
  onSuccess?: () => void,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedUser: UserUpdate) =>
      axiosWithCredentials.put(CURRENT_USER_API, { ...updatedUser }),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        return queryClient.invalidateQueries([CURRENT_USER]);
      },
      onError({ response }) {
        return onError && onError(response);
      },
      useErrorBoundary: ({ response }) =>
        response !== undefined &&
        response?.status >= ERROR_CODES.INTERNAL_ERROR,
    },
  );
};
