import { PropsWithChildren } from "react";
import { BodyName, BodyProps } from "./types";
import { Body } from "./components/Body";

type NamedBodyProps = Omit<BodyProps, "name">;

/**
 * NOTE: Below are all the named <Body> exports based on the Design System
 */

export function Body1({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_1}>
      {children}
    </Body>
  );
}

export function Body2({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_2}>
      {children}
    </Body>
  );
}

export function Body3({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_3}>
      {children}
    </Body>
  );
}

export function Body4({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_4}>
      {children}
    </Body>
  );
}

export function Body5({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_5}>
      {children}
    </Body>
  );
}

export function Body6({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_6}>
      {children}
    </Body>
  );
}

export function Body7({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_7}>
      {children}
    </Body>
  );
}

export function Body8({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_8}>
      {children}
    </Body>
  );
}

export function Body9({
  children,
  ...props
}: PropsWithChildren<NamedBodyProps>) {
  return (
    <Body {...props} name={BodyName.Body_9}>
      {children}
    </Body>
  );
}
