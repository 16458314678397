import * as React from "react";
import type { SVGProps } from "react";
const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.75 12A9.75 9.75 0 0 1 12 21.75 9.75 9.75 0 0 1 2.25 12 9.75 9.75 0 0 1 12 2.25 9.75 9.75 0 0 1 21.75 12Z"
      clipRule="evenodd"
      opacity={0.2}
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12 2.25A9.75 9.75 0 0 1 21.75 12"
    />
  </svg>
);
export default SvgLoading;
