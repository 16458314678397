import { logger } from "@/libs/utils";
import { isDev, isProd } from "@/src/helpers/environment";
import { parseGeoCookie } from "nvs-constants";

// Right now the only things we care about for localization are country and language
// List of Country codes can be found here: https://www.iban.com/country-codes
export const GEOLOC_COOKIE = "geoloc";
export const LANGUAGE_COOKIE = "language";

const escapeRegExp = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

const tldRegExp = /\.[a-z][a-z0-9-]*\b/gi;

/**
 * Returns a Swoosh Handle if the host matches swooshHandle.*
 *
 * @param {(string|undefined)} host Typically context.req.headers.host
 * @returns {(string|undefined)} Swoosh Handle or undefined
 */
export const getSwooshHandleFromHost = (
  host: string | null | undefined,
): string | undefined => {
  if (!host) return undefined;
  let swooshHandle;

  if (!process.env.NEXT_PUBLIC_SITE_URL) {
    return undefined;
  }

  // hostname interpolation in the regex is required because we don't want to
  // intercept calls to the k8s application healthcheck.
  const siteDomain = new URL(process.env.NEXT_PUBLIC_SITE_URL).hostname
    .match(tldRegExp)
    ?.join("");
  if (!siteDomain) return;

  const regex = new RegExp(
    `^(?!(www)\\.)[a-z0-9]+(?=${escapeRegExp(siteDomain)})`,
    "gi",
  );
  const publicProfile = host?.match(regex);

  if (publicProfile) {
    swooshHandle = publicProfile[0];

    // in development environment the required entry in /etc/hosts makes this condition necessary
    // Ex.: 127.0.0.1 local.swoosh-dev.nike
    const localDevelopmentTestHostname = "local.swoosh-dev.nike:3001";
    if (
      (host === localDevelopmentTestHostname || isDev()) &&
      swooshHandle === "local"
    ) {
      return undefined;
    }
  }

  return swooshHandle;
};

export const getGeoLocFromGeoCookie = (geoCookie: any) => {
  if (geoCookie !== undefined) {
    const parsedGeoCookie = parseGeoCookie(geoCookie);
    if (!!parsedGeoCookie && !!parsedGeoCookie.country) {
      return parsedGeoCookie;
    }
  }
  return undefined;
};
