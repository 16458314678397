import { User } from "../schema/user";
import { Country } from "@/libs/geo/countries";
import {
  EXPERIENCE_TYPE,
  CLASSIFICATION_TYPE,
  EVENT_TYPE,
  LaunchProperties,
  ProductProperties,
  OrderProperties,
  CollectionProperties,
  TokenProperties,
  ListingProperties,
} from "./types";
import {
  getPageName,
  globalProperties,
  SCHEMA_LINK,
  swooshIdProperties,
} from "./helpers";
import { PAGE_TYPE } from "nvs-analytics/dist/constants";

// ----------------- PAGE VIEWS -------------------------

export const createLandingPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.landing,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.landing, ""),
  };
  const eventName = "Landing Page Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      swooshId: swooshIdProperties(user),
    },
  };
};

export const createProfilePageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.profile,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.profile, ""),
  };
  const eventName = "Profile Page Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createAccountSettingsPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const eventName = "Account Settings Viewed";

  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.settings,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.settings),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createOnboardingPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.onboarding, ""),
  };
  const eventName = "Onboarding Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      swooshId: swooshIdProperties(user),
    },
  };
};

export const createAccessCodeViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "access code",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.onboarding, "access code"),
  };
  const eventName = "Access Code Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createMFASendCodeViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "mfa send code",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "mfa send code",
    ),
  };
  const eventName = "Mfa Send Code Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createMFAEnterCodeViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "mfa enter code",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "mfa enter code",
    ),
  };
  const eventName = "Mfa Enter Code Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createClaimHandleViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "claim handle",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "claim handle",
    ),
  };
  const eventName = "Claim Handle Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createDesignSwooshIDViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "design swoosh id",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "design swoosh id",
    ),
  };
  const eventName = "Design Swoosh ID Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createSwooshIDBuilderViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "swoosh id builder",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "swoosh id builder",
    ),
  };
  const eventName = "Swoosh ID Builder Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createSecureYourWalletViewedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "secure your wallet",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "secure your wallet",
    ),
  };
  const eventName = "Secure Your Wallet Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createMintSwooshIDViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "mint swoosh id",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "mint swoosh id",
    ),
  };
  const eventName = "Mint Swoosh ID Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createStoryViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  tokenProperties: any,
  collectionsProperties: any,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "story",
    pageType: PAGE_TYPE.tlp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, "story"),
  };
  const eventName = "Story Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...tokenProperties,
      ...collectionsProperties,
    },
  };
};

export const createDetailsViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  tokenProperties: any,
  collectionsProperties: any,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "details",
    pageType: PAGE_TYPE.tlp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, "details"),
  };
  const eventName = "Details Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...tokenProperties,
      ...collectionsProperties,
    },
  };
};

export const createUtilityViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  tokenProperties: any,
  collectionsProperties: any,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "utility",
    pageType: PAGE_TYPE.tlp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, "utility"),
  };
  const eventName = "Utility Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...tokenProperties,
      ...collectionsProperties,
    },
  };
};

export const createPDPViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  launchProperties: LaunchProperties,
  productProperties: ProductProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.pdp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.pdp),
  };
  const eventName = "Product Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      previousView,
      view,
      ...launchProperties,
      products: [productProperties],
      ...globalProperties(country, user),
    },
  };
};

export const createTLPViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: any,
  collection: any,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.tlp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, ""),
  };
  const eventName = "TLP Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...token,
      ...collection,
    },
  };
};

export const createTokenAssetViewedView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: any,
  collection: any,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "gallery",
    pageType: PAGE_TYPE.tlp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, "gallery"),
  };
  const eventName = "Token Asset Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...token,
      ...collection,
    },
  };
};

export const createTransactionPendingPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  order: OrderProperties,
  launch: LaunchProperties,
  product: ProductProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "pending",
    pageType: PAGE_TYPE.checkout,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.checkout, "pending"),
  };
  const eventName = "Transaction Pending Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      previousView,
      view,
      ...order,
      ...launch,
      products: [product],
      ...globalProperties(country, user),
    },
  };
};

export const createGotEmViewedPageView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  order: OrderProperties,
  launch: LaunchProperties,
  product: ProductProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "got em",
    pageType: PAGE_TYPE.checkout,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.checkout, "got em"),
  };
  const eventName = "Got Em Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      previousView,
      view,
      ...order,
      ...launch,
      products: [product],
      ...globalProperties(country, user),
    },
  };
};

export const createPDPErrorViewedView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  message: { title: string; body: string },
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "error",
    pageType: PAGE_TYPE.pdp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.pdp, "error"),
  };
  const eventName = "Error Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      previousView,
      view,
      message,
      ...globalProperties(country, user),
    },
  };
};

export const createRevealViewedView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  launchProperties: LaunchProperties,
  collection: CollectionProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "reveal viewed",
    pageType: PAGE_TYPE.reveal,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.reveal, "revealViewed"),
  };
  const eventName = "Reveal Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      previousView,
      view,
      collection,
      ...launchProperties,
      ...globalProperties(country, user),
    },
  };
};

export const createCLPViewedView = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  collection: CollectionProperties,
  sortBy: any,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.clp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.clp, ""),
  };
  const eventName = "CLP Viewed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.page,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      previousView,
      view,
      collection,
      ...sortBy,
      ...globalProperties(country, user),
    },
  };
};

// ----------------- TRACK EVENTS -------------------------

export const createBuyNowClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  launchProperties: LaunchProperties,
  productProperties: ProductProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.pdp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.pdp),
  };
  const eventName = "Buy Now Clicked";
  const clickActivity = "nvs:buy now";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      previousView,
      view,
      ...launchProperties,
      clickActivity: clickActivity,
      products: [productProperties],
      ...globalProperties(country, user),
    },
  };
};

export const createJoinClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.landing,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.landing, ""),
  };
  const eventName = "Join Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createJoinClickedGlobalNavEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.globalNav,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.globalNav, ""),
  };
  const eventName = "Join Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createSignInToNikeEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  pageType: PAGE_TYPE,
  country: Partial<Country> | string | undefined,
  eventName = "Sign in Clicked",
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: pageType,
    pageName: getPageName(EXPERIENCE_TYPE, pageType, ""),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createMFACodeRedeemedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "mfa enter code",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "mfa enter code",
    ),
  };
  const eventName = "MFA Code Redeemed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createAccessCodeRedeemedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  accessCode: string,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "access code",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.onboarding, "access code"),
  };
  const eventName = "Access Code Redeemed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      swoosh: {
        accessCode: accessCode,
      },
    },
  };
};

export const createJoinForcesClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "lets connect",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "lets connect",
    ),
  };
  const eventName = "Follow Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createSwooshHandleClaimedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "update handle",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "update handle",
    ),
  };
  const eventName = "Handle Updated";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createNikeSignInCompletedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "nike sign in",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "nike sign in",
    ),
  };
  const eventName = "Nike Sign In Completed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createSwooshIdDesignSavedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "swoosh id builder",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "swoosh id builder",
    ),
  };
  const eventName = "Swoosh ID Design Saved";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      swooshId: swooshIdProperties(user),
    },
  };
};

export const createSwooshIdBuilderLaunchedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "design swoosh id",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "design swoosh id",
    ),
  };
  const eventName = "Swoosh ID Builder Launched";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      swooshId: swooshIdProperties(user),
    },
  };
};

export const createSwooshIdMintedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "mint swoosh id",
    pageType: PAGE_TYPE.onboarding,
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.onboarding,
      "mint swoosh id",
    ),
  };
  const eventName = "Swoosh ID Minted";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      swooshId: swooshIdProperties(user),
    },
  };
};

export const createHeroPrimaryCTAClicked = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  collection: CollectionProperties,
) => {
  const eventName = "Hero Primary CTA Clicked";
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageType: PAGE_TYPE.landing,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.landing),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      collection,
    },
  };
};

export const createNavMembershipClicked = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
) => {
  const eventName = "Nav Membership Clicked";
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageType: PAGE_TYPE.globalNav,
    pageDetail: "nav membership clicked",
    pageName: getPageName(
      EXPERIENCE_TYPE,
      PAGE_TYPE.globalNav,
      "nav membership clicked",
    ),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
    },
  };
};

export const createUtilityFAQClicked = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: any,
) => {
  const eventName = "Utility FAQ Clicked";
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageType: PAGE_TYPE.tlp,
    pageDetail: "utility",
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, "utility"),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...token,
    },
  };
};

export const createTokenClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: any,
  collection: any,
  pageType: string,
) => {
  const pageDetail = pageType == "profile" ? "owned" : "";
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: pageDetail,
    pageType: pageType,
    pageName: getPageName(EXPERIENCE_TYPE, pageType, pageDetail),
  };
  const eventName = "Token Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      ...token,
      ...collection,
    },
  };
};

export const createCheckoutStartedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  order: { orderType: string; orderId: string },
  launch: LaunchProperties,
  product: ProductProperties,
) => {
  const eventName = "Checkout Started";
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageType: PAGE_TYPE.checkout,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.checkout),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      view,
      previousView,
      ...order,
      ...launch,
      products: [product],
      ...globalProperties(country, user),
    },
  };
};

export const createCheckoutCompletedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  order: { orderType: string; orderId: string },
  launch: LaunchProperties,
  product: ProductProperties,
) => {
  const eventName = "Checkout Completed";
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageType: PAGE_TYPE.checkout,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.checkout),
  };

  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.coreBuyFlow,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName,
      view,
      previousView,
      ...order,
      ...launch,
      products: [product],
      ...globalProperties(country, user),
    },
  };
};

export const createTokenRevealedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: TokenProperties,
  collection: CollectionProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.reveal,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.reveal, ""),
  };
  const eventName = "Token Revealed";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      token,
      collection,
    },
  };
};

export const createReveaClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: TokenProperties,
  collection: CollectionProperties,
  launch: LaunchProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.reveal,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.reveal, ""),
  };
  const eventName = "Reveal Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      token,
      collection,
      ...launch,
      clickActivity: "nvs:reveal",
    },
  };
};

export const createTokenFavoritedTLPEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: TokenProperties,
  collection: CollectionProperties,
  listing?: ListingProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.tlp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.tlp, ""),
  };
  const eventName = "Token Favorited";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      token,
      collection,
      listing,
      clickActivity: "nvs:token favorited",
    },
  };
};

export const createTokenFavoritedCLPEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: TokenProperties,
  collection: CollectionProperties,
  sortBy: any,
  appliedFilters: any,
  listing?: ListingProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.clp,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.clp, ""),
  };
  const eventName = "Token Favorited";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      token,
      collection,
      listing,
      ...sortBy,
      ...appliedFilters,
      clickActivity: "nvs:token favorited",
    },
  };
};

export const createViewDetailsReveaClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: TokenProperties,
  collection: CollectionProperties,
  launch: LaunchProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.reveal,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.reveal, ""),
  };
  const eventName = "View Details Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      token,
      collection,
      ...launch,
      clickActivity: "nvs:view details",
    },
  };
};

export const createReplayReveaClickedEvent = (
  user: Partial<User> | undefined,
  previousView: any,
  country: Partial<Country> | string | undefined,
  token: TokenProperties,
  collection: CollectionProperties,
  launch: LaunchProperties,
) => {
  const view = {
    experienceType: EXPERIENCE_TYPE,
    pageDetail: "",
    pageType: PAGE_TYPE.reveal,
    pageName: getPageName(EXPERIENCE_TYPE, PAGE_TYPE.reveal, ""),
  };
  const eventName = "Replay Clicked";
  return {
    name: eventName,
    options: {
      $schema: SCHEMA_LINK(
        CLASSIFICATION_TYPE.experienceEvent,
        view.pageType,
        EVENT_TYPE.track,
        eventName.replace(/\s+/g, "-").toLowerCase(),
      ),
    },
    properties: {
      eventName: eventName,
      ...globalProperties(country, user),
      view,
      previousView,
      token,
      collection,
      ...launch,
      clickActivity: "nvs:replay",
    },
  };
};
