import { noop } from "lodash";
import Script from "next/script";
import {
  createContext,
  use,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCurrentUser } from "../apis/onboarding/useUser";
import { User } from "../schema/user";
import { PreviousView } from "./types";
import { isClient } from "../utils";
import UAParser from "ua-parser-js";

type OsType = { name?: string; version?: string };

export type AnalyticsContextShape = {
  previousView: PreviousView;
  setPreviousView: (previousView: PreviousView) => void;
  country?: string;
  currentUser: Partial<User> | undefined;
  isCurrentUserLoading: boolean;
  isReady?: boolean;
  anonymousId?: string;
  os?: OsType;
};

const loadAnalytics = () => {
  window.analyticsClient?.load(
    process.env.NEXT_PUBLIC_ANALYTICS_WRTIE_KEY || "nvs-dev",
  );
};

const analyticsData = () => {
  const uaParser = UAParser(window.navigator.userAgent);

  const analytics = window.analyticsClient;
  const anonymousId = analytics?._anonymousId as string;
  const os = {
    name: uaParser.os.name,
    version: uaParser.os.version,
  };

  return { anonymousId, os };
};

export const AnalyticsContext = createContext<AnalyticsContextShape>({
  previousView: {
    experienceType: "",
    pageDetail: "",
    pageType: "",
    pageName: "",
  },
  setPreviousView: noop,
  isReady: false,
  country: "",
  currentUser: undefined,
  isCurrentUserLoading: true,
  anonymousId: "",
  os: { name: "", version: "" },
});

export function useAnalyticsContext() {
  return useContext(AnalyticsContext);
}

export function AnalyticsContextProvider({
  children,
  country,
}: {
  children: React.ReactNode;
  country?: string;
}) {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [os, setOs] = useState<OsType>({ name: "", version: "" });
  const [anonymousId, setAnonymousId] = useState<string>("");
  const [previousView, setPreviousView] = useState<PreviousView>({
    experienceType: "",
    pageDetail: "",
    pageType: "",
    pageName: "",
  });
  useEffect(() => {
    if (isClient()) {
      const data = analyticsData();
      setAnonymousId(data?.anonymousId);
      setOs(data?.os);
    }
  }, []);

  // user object should be empty until they have been mfa'd and page loads
  const { currentUser, isLoading } = useCurrentUser();

  const value = {
    setPreviousView,
    country,
    currentUser,
    isReady,
    previousView,
    isCurrentUserLoading: currentUser == undefined || isLoading,
    anonymousId,
    os,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      <Script
        src="/libs/analytics/analytics-client.min.js"
        strategy="afterInteractive"
        onLoad={() => {
          if (window.analyticsClient) {
            loadAnalytics();
            setIsReady(true);
          }
        }}
      />
      {children}
    </AnalyticsContext.Provider>
  );
}
