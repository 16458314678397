import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useState } from "react";
import ReactQueryErrorFallback from "./ReactQueryErrorBoundary";

const QueryClientProviderWrapper: React.FC<{
  children: React.ReactNode;
  dehydratedState?: DehydratedState;
}> = ({ children, dehydratedState }) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryErrorFallback>
        <Hydrate state={dehydratedState}>{children}</Hydrate>
      </ReactQueryErrorFallback>
      <ReactQueryDevtools
        initialIsOpen={false}
        position="bottom-left"
        panelProps={{ style: { height: "400px" } }}
      />
    </QueryClientProvider>
  );
};

export default QueryClientProviderWrapper;
