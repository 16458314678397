import * as React from "react";
import type { SVGProps } from "react";
const SvgSortAscending = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 14.25H3M7.5 9.75H3M16.5 18.75H3M13.5 9.29l3.71-3.71 3.71 3.71M17.25 15V6"
    />
  </svg>
);
export default SvgSortAscending;
