import * as React from "react";
import type { SVGProps } from "react";
const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M11.99 18v-1.5M9 9.75a3 3 0 1 1 4.29 2.71c-.78.37-1.29 1.16-1.29 2.03V15"
    />
  </svg>
);
export default SvgQuestion;
