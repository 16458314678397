import { useStatusBanner } from "@/src/components/StatusBannerContainer/hooks/useStatusBanner";
import { StatusBanner } from "nvs-design-system/dist/StatusBanner";

export function StatusBannerContainer() {
  const statusBanner = useStatusBanner();

  function getLink() {
    if (statusBanner.link && statusBanner.linkCta) {
      return <a href={statusBanner.link}>{statusBanner.linkCta}</a>;
    }
    return null;
  }

  return (
    <StatusBanner
      className="z-globalNav"
      ariaLabel=".SWOOSH Status Banner"
      dialogId="global-status-banner"
      open={statusBanner.isVisible}
      copy={statusBanner.message}
      bannerType={statusBanner.color}
      onClose={statusBanner.setDismissed}
      link={getLink()}
    />
  );
}
