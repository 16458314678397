import * as React from "react";
import type { SVGProps } from "react";
const SvgSortDescending = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      clipPath="url(#sort-descending_svg__a)"
    >
      <path d="M12.01 9.75h-9M7.51 14.25h-4.5M16.51 5.25H3.01M17.26 9v9M20.97 14.73l-3.71 3.71-3.71-3.71" />
    </g>
    <defs>
      <clipPath id="sort-descending_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSortDescending;
