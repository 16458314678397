import * as React from "react";
import type { SVGProps } from "react";
const SvgShipping = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M8.25 16.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0ZM20.25 16.533a2.25 2.25 0 1 1-4.502-.001 2.25 2.25 0 0 1 4.502.001Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.75 16.565H8.5M.75 11.5V14A2.25 2.25 0 0 0 3 16.25h.25M19.5 9.75H3A2.25 2.25 0 0 1 .75 7.5V5.25h17.189c.2 0 .39.079.531.22l4.56 4.56a.75.75 0 0 1 .22.531v3.689A2.25 2.25 0 0 1 21 16.5h-.75"
    />
  </svg>
);
export default SvgShipping;
