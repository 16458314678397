import clsx from "clsx";
import { Country } from "@/libs/geo/countries";
import {
  ROUTE_LEGAL_DIGITAL_COLLECTIBLE_TERMS,
  ROUTE_LEGAL_PRIVACY_POLICY,
  ROUTE_LEGAL_TERMS_OF_SERVICE,
} from "@/constants/routes";
import { useGeoLoc } from "@/libs/geo/GeoLocProvider";

export enum AgreementType {
  TermsOfService = "NvsTermsOfService",
  PrivacyPolicy = "NvsPrivacyPolicy",
  DigitalCollectible = "NvsDigCollectible",
}

export const AgreementToRouteMap = {
  [AgreementType.TermsOfService]: ROUTE_LEGAL_TERMS_OF_SERVICE,
  [AgreementType.PrivacyPolicy]: ROUTE_LEGAL_PRIVACY_POLICY,
  [AgreementType.DigitalCollectible]: ROUTE_LEGAL_DIGITAL_COLLECTIBLE_TERMS,
};

const AGREEMENT_TEXT = {
  [AgreementType.TermsOfService]: "Terms and Conditions",
  [AgreementType.PrivacyPolicy]: "Privacy Policy",
  [AgreementType.DigitalCollectible]: "Digital Collectible Terms",
};

type LegalAgreementLinkProps = {
  agreementType: AgreementType;
  className?: string;
};

export const getLocalizedLegalAgreement = (
  agreementType: AgreementType,
  country: Country,
) => {
  const language = "en";
  const route = AgreementToRouteMap[agreementType];
  return `${route}?country=${country}&language=${language}`;
};

const LegalAgreementLink: React.FC<LegalAgreementLinkProps> = ({
  agreementType,
  className,
}) => {
  const geoLoc = useGeoLoc();
  const country = geoLoc.country as Country;
  const url = getLocalizedLegalAgreement(agreementType, country);

  return (
    <a
      href={url}
      className={clsx("underline", className)}
      target="_blank"
      rel="noreferrer"
    >
      {AGREEMENT_TEXT[agreementType]}
    </a>
  );
};

export default LegalAgreementLink;
