import * as React from "react";
import type { SVGProps } from "react";
const SvgShopNrc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.844 12.852 3.352-5.757-2.665 6.554c-.288.706-.826 1.726-1.67 1.53-.47-.109-.807-.674-.95-1.35-.093-.434-.116-.894-.137-1.352.075.317.39 1.317 1.168 1.198.3-.049.62-.342.902-.823"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M11.99 6.75a4.521 4.521 0 0 0 4.312-3.164c.17-.542.841-.723 1.244-.32l3.541 3.54a2.245 2.245 0 0 1 0 3.174l-10.27 10.27H4.138a1.888 1.888 0 0 1-.609-3.676l2.567-.873a2.651 2.651 0 0 0 1.706-1.823L11 1.941"
    />
  </svg>
);
export default SvgShopNrc;
