import * as React from "react";
import type { SVGProps } from "react";
const SvgTip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12 1.5v3M1.5 12h3m15 0h3M4.604 4.646l2.122 2.122m12.672-2.121-2.122 2.12M9 20.25h6m-7.5-8.5a4.5 4.5 0 1 1 6.75 3.898v2.102h-4.5v-2.102A4.498 4.498 0 0 1 7.5 11.75Z"
    />
  </svg>
);
export default SvgTip;
