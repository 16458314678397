import * as React from "react";
import type { SVGProps } from "react";
const SvgSingleSelect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M20.25 7.5v9c0 1.24-1.01 2.25-2.25 2.25H9c-1.24 0-2.25-1.01-2.25-2.25v-9c0-1.24 1.01-2.25 2.25-2.25h9c1.24 0 2.25 1.01 2.25 2.25ZM3.75 11.25v1.5"
    />
  </svg>
);
export default SvgSingleSelect;
