import * as React from "react";
import type { SVGProps } from "react";
const SvgGift = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M3.76 10.5V8.25h16.5v3H6.76c-.83 0-1.5.67-1.5 1.5v6c0 .83.67 1.5 1.5 1.5h10.5c.83 0 1.5-.67 1.5-1.5v-7.5M14.26 3.75c-1.24 0-2.25 1.01-2.25 2.25v2.25h2.25c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25ZM9.76 3.75c1.24 0 2.25 1.01 2.25 2.25v2.25H9.76C8.51 8.25 7.51 7.24 7.51 6s1-2.25 2.25-2.25ZM12.01 8.53v11.72"
    />
  </svg>
);
export default SvgGift;
