import * as React from "react";
import type { SVGProps } from "react";
const SvgAr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m9.389 10.5 2.61 1.5 2.613-1.5M12 12v2.5M12 17v2.814M18.75 15.917 16.308 14.5M5.25 15.917 7.692 14.5M12 7.314V4.5M5.25 8.397l2.442 1.417M18.75 8.397l-2.442 1.417M9.433 18.332 12 19.814l2.567-1.482M14.567 5.982 12 4.5 9.433 5.982M16.183 17.4l2.567-1.483v-2.964M18.75 11.361V8.397l-2.567-1.482M7.817 17.4 5.25 15.916v-2.964M5.25 11.361V8.397l2.567-1.482"
    />
  </svg>
);
export default SvgAr;
