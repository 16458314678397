import { PropsWithChildren } from "react";
import { HeadlineName, HeadlineProps } from "./types";
import { Headline } from "./components/Headline";
import clsx from "clsx";

type NamedHeadlineProps = Omit<HeadlineProps, "name">;

/**
 * NOTE: Below are all the named <Headline> exports based on the Design System
 */

export function Headline1({
  children,
  ...props
}: PropsWithChildren<NamedHeadlineProps>) {
  return (
    <Headline as="h1" {...props} name={HeadlineName.Headline_1}>
      {children}
    </Headline>
  );
}

export function Headline2({
  children,
  ...props
}: PropsWithChildren<NamedHeadlineProps>) {
  return (
    <Headline as="h2" {...props} name={HeadlineName.Headline_2}>
      {children}
    </Headline>
  );
}

export function Headline3({
  children,
  ...props
}: PropsWithChildren<NamedHeadlineProps>) {
  return (
    <Headline as="h3" {...props} name={HeadlineName.Headline_3}>
      {children}
    </Headline>
  );
}

export function Headline4({
  children,
  ...props
}: PropsWithChildren<NamedHeadlineProps>) {
  return (
    <Headline as="h4" {...props} name={HeadlineName.Headline_4}>
      {children}
    </Headline>
  );
}

export function Headline5({
  children,
  ...props
}: PropsWithChildren<NamedHeadlineProps>) {
  return (
    <Headline as="h5" {...props} name={HeadlineName.Headline_5}>
      {children}
    </Headline>
  );
}

export function Headline6({
  children,
  ...props
}: PropsWithChildren<NamedHeadlineProps>) {
  return (
    <Headline as="h6" {...props} name={HeadlineName.Headline_6}>
      {children}
    </Headline>
  );
}
