import * as React from "react";
import type { SVGProps } from "react";
const SvgTransfers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M4.25 14.25H18c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25h-7.5"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m6.71 16.9-2.65-2.65 2.65-2.65M19.75 9.75H6c-1.24 0-2.25-1.01-2.25-2.25S4.76 5.25 6 5.25h7.5"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m17.29 7.1 2.65 2.65-2.65 2.65"
    />
  </svg>
);
export default SvgTransfers;
