import { leftFooterItems } from "../../constants";
import { FooterItem } from "./FooterItem";

const LeftFooter = () => {
  return (
    <div className="flex flex-row text-sm uppercase text-white ">
      {leftFooterItems.map((footerItem) => (
        <FooterItem
          className="pr-8 text-white"
          {...footerItem}
          key={footerItem.key}
        />
      ))}
    </div>
  );
};

export default LeftFooter;
