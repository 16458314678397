import * as React from "react";
import type { SVGProps } from "react";
const SvgReturn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M3.75 21V9.75l1.61-6h13.28l1.61 6V18c0 1.24-1.01 2.25-2.25 2.25M18 20.25H8M20.25 9.75H3.75"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m9.71 22.9-2.65-2.65 2.65-2.65M12 3.75v9.75"
    />
  </svg>
);
export default SvgReturn;
