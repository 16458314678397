import * as React from "react";
import type { SVGProps } from "react";
const SvgClub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M2.25 21v-3A3.75 3.75 0 0 1 6 14.25h6A3.75 3.75 0 0 1 15.75 18v3M15 12.75h3a3.75 3.75 0 0 1 3.75 3.75v3"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12.75 7.5a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM20.25 7.5a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClub;
