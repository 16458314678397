import clsx from "clsx";
import { PropsWithChildren } from "react";

export type IconContainerProps = {
  className?: string;
  display?: string;
  size?: keyof typeof ICON_SIZE_CLASS_MAP;
};

export enum IconSize {
  xs = "xs", // 14px
  sm = "sm", // 16px
  base = "base", // 24px
  md = "md", // 32px
  lg = "lg", // 48px
  xl = "xl", // 80px
}

export const ICON_SIZE_CLASS_MAP = {
  [IconSize.xs]: "w-3.5 h-3.5",
  [IconSize.sm]: "w-4 h-4",
  [IconSize.base]: "w-6 h-6",
  [IconSize.md]: "w-8 h-8",
  [IconSize.lg]: "w-12 h-12",
  [IconSize.xl]: "w-20 h-20",
};

const DEFAULT_DISPLAY = "block";

export const ICON_CONTAINER_TEST_ID = "icon-container";

export function IconContainer({
  size = IconSize.base,
  display = DEFAULT_DISPLAY,
  ...props
}: PropsWithChildren<IconContainerProps>) {
  return (
    <span
      className={clsx(props.className, display, ICON_SIZE_CLASS_MAP[size])}
      data-testid={ICON_CONTAINER_TEST_ID}
    >
      {props.children}
    </span>
  );
}
