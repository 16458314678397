import { convertApiUserToUser } from "@/libs/mappers/user";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { User } from "@/libs/schema/user";
import { useState, useEffect } from "react";
import { CURRENT_USER } from "./reactQueryCacheKeys";
import { CURRENT_USER_API } from "./user/common";
import { ERROR_CODES } from "@/libs/error/types";
import { makeApiUserCall } from "./user/useUser/api";

export const useUser = () => {
  const { isLoading, error, data } = useQuery<
    Partial<User>,
    AxiosError,
    Partial<User>
  >([CURRENT_USER], makeApiUserCall, {
    retry: false,
    refetchOnMount: false,
    enabled: true,
    useErrorBoundary: ({ response }) =>
      response !== undefined && response?.status >= ERROR_CODES.INTERNAL_ERROR,
  });
  return { isLoading, error, user: data };
};

export type CurrentUser = Partial<User>;

export const useCurrentUser = () => {
  const { error, user, isLoading } = useUser();

  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>(
    undefined,
  );
  useEffect(() => {
    if (user && !error) {
      setCurrentUser(user);
    } else if (error) {
      setCurrentUser({}); // By the time we get here we will have handled other errors. This just handles if user does not exist. We will handle other errors that are not 400 in the useUser hook
    }
  }, [user, error]);
  return { currentUser, isLoading }; // isLoading and currentUser === undefined are the same thing, but both are exposed for clarity
};
