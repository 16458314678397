import { PropsWithChildren } from "react";
import { BodyProps } from "../types";
import { getBodyStyles } from "../helpers";
import { PolymorphicComponent } from "@/src/components/PolymorphicComponent";

export function Body({
  as = "p",
  name,
  children,
  className,
  md,
  ...props
}: PropsWithChildren<BodyProps>) {
  return (
    <PolymorphicComponent
      as={as}
      className={getBodyStyles({ className, name, md })}
      {...props}
    >
      {children}
    </PolymorphicComponent>
  );
}
