import * as React from "react";
import type { SVGProps } from "react";
const SvgMultiSelect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M20.25 6.75V9c0 .83-.67 1.5-1.5 1.5H8.25c-.83 0-1.5-.67-1.5-1.5V6.75c0-.83.67-1.5 1.5-1.5h10.5c.83 0 1.5.67 1.5 1.5ZM20.25 15v2.25c0 .83-.67 1.5-1.5 1.5H8.25c-.83 0-1.5-.67-1.5-1.5V15c0-.83.67-1.5 1.5-1.5h10.5c.83 0 1.5.67 1.5 1.5ZM3 16.25h1.5M3 7.75h1.5"
    />
  </svg>
);
export default SvgMultiSelect;
