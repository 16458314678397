"use client";

import { optimizelyInstance } from "@/src/api/routes/optimizely/constants";
import fallbackJson from "@/src/services/optimizely/fallbackDatafile.json";

export const fetchOptimizelyClientData = () => {
  return (
    optimizelyInstance.getOptimizelyConfig()?.getDatafile() || fallbackJson
  );
};
