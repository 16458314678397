import Head from "next/head";
import config from "tailwind.config.js";

export function getDarkThemeStyle() {
  return `html { background: ${config.theme.colors.gray[950]}; }`;
}

/**
 *
 * While we don't really have the concepts of themes, we do have a lot of dark pages.
 * To optimize the way these pages feel on initial load, we are setting some html styles
 * to match the most commonly used background color.
 */

export function DarkThemeStyle() {
  return (
    <Head>
      <style>{getDarkThemeStyle()}</style>
    </Head>
  );
}
