import * as React from "react";
import type { SVGProps } from "react";
const SvgThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.25 13.5c0 1.86.76 3.55 1.98 4.77A6.735 6.735 0 0 0 11 20.25h6.25c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-.75 1.75c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H16.5h1.75c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H16.5h1.75c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-7.68c-.38 0-.63-.42-.43-.75l1.73-3A1.501 1.501 0 0 0 9.27 3l-4.15 7.2c-.56.97-.87 2.1-.87 3.3Zm0 0c0 3.73 3.02 6.75 6.75 6.75"
    />
  </svg>
);
export default SvgThumbsUp;
