import clsx from "clsx";
import { PropsWithChildren } from "react";

export enum GridBackgroundType {
  dark = "dark",
  light = "light",
  /**
   * When you need to overlay the grid pattern on top of an image/media item.
   */
  overlay = "overlay",
}

export type GridBackgroundTypes = keyof typeof GridBackgroundType;

type GridBackgroundProps = PropsWithChildren<{
  className?: string;
  type?: GridBackgroundTypes;
}>;

export const GRID_BACKGROUND_CLASS_BY_TYPE = {
  [GridBackgroundType.dark]: "bg-gray-950 bg-grid-dark",
  [GridBackgroundType.light]: "bg-white bg-grid-light",
  [GridBackgroundType.overlay]: "absolute inset-0 bg-grid-overlay",
};

export function GridBackground({
  type = GridBackgroundType.dark,
  ...props
}: GridBackgroundProps) {
  return (
    <div
      className={clsx(
        "bg-grid",
        props.className,
        GRID_BACKGROUND_CLASS_BY_TYPE[type],
      )}
    >
      {props.children}
    </div>
  );
}
