import * as React from "react";
import type { SVGProps } from "react";
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.75 13.5a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.5 6.75h2A2.25 2.25 0 0 1 21.75 9v7.5A3.75 3.75 0 0 1 18 20.25H6a3.75 3.75 0 0 1-3.75-3.75V9A2.25 2.25 0 0 1 4.5 6.75h9.75a1.5 1.5 0 1 0 0-3h-4.5a1.5 1.5 0 0 0-1.5 1.5V6M6 9.75H4.5"
    />
  </svg>
);
export default SvgCamera;
