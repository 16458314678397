import { isProd } from "@/src/helpers/environment";
import { OptimizelyFeatureFlags } from "@/src/services/optimizely/optimizelyFeatureFlags";
import { OptimizelyDecision, enums } from "@optimizely/react-sdk";
import { get, size } from "lodash";

export const getDecisionFromOptimizelyDecisionsAndFlags = (
  flags?: OptimizelyFeatureFlags[],
  decisions?: Record<string, OptimizelyDecision>,
) => {
  if (decisions == undefined) return false;
  if (flags === undefined) return true;
  const flagsStatus = flags.map((flag) =>
    get(decisions[flag], "enabled", false),
  );
  return size(flagsStatus.filter((flag) => flag === false)) === 0;
};

export const getOptimizelyLogLevel = () => {
  const isDebugOptimizelyLogOn =
    process.env.NEXT_PUBLIC_OPTIMIZELY_DEBUG_LOG === "true";

  return isProd()
    ? enums.LOG_LEVEL.ERROR
    : isDebugOptimizelyLogOn
      ? enums.LOG_LEVEL.INFO
      : enums.LOG_LEVEL.ERROR;
};
