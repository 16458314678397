import * as React from "react";
import type { SVGProps } from "react";
const SvgTryOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.13 11.33 5.58 3.22c1.99 1.15 1.18 4.2-1.12 4.2H5.41c-2.3 0-3.12-3.05-1.12-4.2l9.01-5.2a3.005 3.005 0 0 0 1.1-4.1 2.996 2.996 0 0 0-4.1-1.1c-.96.56-1.5 1.56-1.5 2.6"
    />
  </svg>
);
export default SvgTryOn;
