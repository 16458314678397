import * as React from "react";
import type { SVGProps } from "react";
const SvgPlusMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9 10.5H3M21 14.25h-6M6 13.5v-6M7.5 19.794l9-15.588"
    />
  </svg>
);
export default SvgPlusMinus;
