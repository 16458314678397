import { AnalyticsContextProvider } from "@/libs/analytics/AnalyticsProvider";
import DocumentHeadDefaults from "../DocumentHeadDefaults";
import ToastProvider from "../Toast/ToastProvider";
import { useGeoLoc } from "@/libs/geo/GeoLocProvider";
import { StatusBannerContainer } from "../StatusBannerContainer";

type LayoutProps = React.PropsWithChildren;

const BaseLayout = ({ children }: LayoutProps) => {
  const { country } = useGeoLoc();

  return (
    <AnalyticsContextProvider country={country}>
      <ToastProvider>
        <DocumentHeadDefaults />
        <StatusBannerContainer />
        {children}
      </ToastProvider>
    </AnalyticsContextProvider>
  );
};

export default BaseLayout;
