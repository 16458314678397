import * as React from "react";
import type { SVGProps } from "react";
const SvgComments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16 17.25h2A2.25 2.25 0 0 0 20.25 15V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v9A2.25 2.25 0 0 0 6 17.25h6a2.25 2.25 0 0 1 2.25 2.25V21"
    />
  </svg>
);
export default SvgComments;
