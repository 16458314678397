import clsx from "clsx";
import React from "react";

type Props = { classes?: string };

const ModalDivider: React.FC<Props> = ({ classes }) => {
  return (
    <hr className={clsx("border-primary border-dashed px-[3%]", classes)} />
  );
};

export default ModalDivider;
