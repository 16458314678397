import * as React from "react";
import type { SVGProps } from "react";
const SvgScan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M2.25 18v1.75a2 2 0 0 0 2 2H6M18 21.75h1.75a2 2 0 0 0 2-2V18M21.75 6V4.25a2 2 0 0 0-2-2H18M6 2.25H4.25a2 2 0 0 0-2 2V6M13.75 6v12M6.75 6v12M17.25 6v12M10.25 6v12"
    />
  </svg>
);
export default SvgScan;
