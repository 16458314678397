import { PropsWithChildren } from "react";
import { TitleName, TitleProps } from "./types";
import { Title } from "./components/Title";

type NamedTitleProps = Omit<TitleProps, "name">;

/**
 * NOTE: Below are all the named <Title> exports based on the Design System
 */

export function Title1({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h1" {...props} name={TitleName.Title_1}>
      {children}
    </Title>
  );
}

export function Title2({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h2" {...props} name={TitleName.Title_2}>
      {children}
    </Title>
  );
}

export function Title3({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h3" {...props} name={TitleName.Title_3}>
      {children}
    </Title>
  );
}

export function Title4({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h4" {...props} name={TitleName.Title_4}>
      {children}
    </Title>
  );
}

export function Title5({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h5" {...props} name={TitleName.Title_5}>
      {children}
    </Title>
  );
}

export function Title6({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h6" {...props} name={TitleName.Title_6}>
      {children}
    </Title>
  );
}

export function Title7({
  children,
  ...props
}: PropsWithChildren<NamedTitleProps>) {
  return (
    <Title as="h6" {...props} name={TitleName.Title_7}>
      {children}
    </Title>
  );
}
