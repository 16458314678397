import * as React from "react";
import type { SVGProps } from "react";
const SvgEllipsisVerticalMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 3 16"
    {...props}
  >
    <circle cx={1.5} cy={2} r={1.5} fill="currentColor" />
    <circle cx={1.5} cy={8} r={1.5} fill="currentColor" />
    <circle cx={1.5} cy={14} r={1.5} fill="currentColor" />
  </svg>
);
export default SvgEllipsisVerticalMore;
