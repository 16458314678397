import {
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_HOME,
  ROUTE_ORDER_HISTORY,
} from "@/constants/routes";
import { getUserProfileRoute } from "@/src/helpers/getUserProfileRoute";
import { noop, size } from "lodash";
import { signOut } from "next-auth/react";
import useIsFeatureFlagEnabled from "@/src/services/optimizely/useIsFeatureFlagEnabled";
import { OptimizelyFeatureFlags } from "@/src/services/optimizely/optimizelyFeatureFlags";
import { useEffect, useState } from "react";
import { GlobalNavLink } from "nvs-design-system/dist/GlobalNav/types";

export const useProfileMenuLinks = (swooshHandle: string | undefined) => {
  const { enabled: preOrderEnabled, clientReady } = useIsFeatureFlagEnabled(
    OptimizelyFeatureFlags.PREORDER_PILOT,
  );

  const [profileLinks, setProfileLinks] = useState<GlobalNavLink[]>([]);

  useEffect(() => {
    if (!!!swooshHandle) return () => {};

    const orderHistory =
      preOrderEnabled && clientReady
        ? {
            __typename: "Link",
            text: "Orders",
            url: ROUTE_ORDER_HISTORY,
            onClick: () => {
              noop;
            },
          }
        : {};

    const links = [
      {
        __typename: "Link",
        text: "Account Settings",
        url: ROUTE_ACCOUNT_SETTINGS,
        onClick: () => {
          noop;
        }, // // global nav page analytics event to go here
      },
      orderHistory,
      {
        __typename: "Link",
        text: "Profile",
        url: getUserProfileRoute(swooshHandle),
        onClick: noop, // global nav page analytics event to go here
      },
      {
        __typename: "Link",
        text: "",
        url: "",
      }, // empty object to create spacing before Sign Out Link
      {
        __typename: "Link",
        text: "Log Out",
        url: "",
        onClick: () => signOut({ callbackUrl: ROUTE_HOME }),
      },
    ];

    const filterLinks = links.filter((item) => size(item) > 0);

    setProfileLinks(filterLinks as GlobalNavLink[]);
  }, [clientReady, preOrderEnabled, swooshHandle]);

  return profileLinks;
};
