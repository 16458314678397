import clsx from "clsx";
import React from "react";
import CustomCheckbox from "../../../form/checkbox/StyledCheckbox";
import { cookiePreferencesCopy, CookiePreference } from "../copy";
import { CookiePreferences as CookieSettings } from "@/libs/schema/user";
import { Controller, useForm } from "react-hook-form";
import OnboardingSecondaryButton from "@/src/components/OnboardingSecondaryButton";

const AcceptPreferencesButton: React.FC = () => {
  return (
    <div className="laptop:my-5 flex w-full flex-row justify-center">
      <OnboardingSecondaryButton
        buttonText={cookiePreferencesCopy.buttonCTA}
        style="inverse"
        type="submit"
        classes={clsx("basis-full uppercase py-2", "laptop:basis-2/5")}
      />
    </div>
  );
};

type Props = {
  initialValues: CookieSettings;
  onSubmit: (data: CookieSettings) => void;
};

const CookiePreferences: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const { handleSubmit, control } = useForm<CookieSettings>({
    mode: "onSubmit",
    defaultValues: initialValues,
  });

  return (
    <form
      className="laptop:gap-4 flex flex-col gap-6"
      onSubmit={handleSubmit(async (data) => {
        onSubmit(data);
        /**
         * Note we are automatically reloading the page on cookie change to allow
         * new relic performance cookie tracking to be turned off and on
         **/
        window.location.reload();
      })}
    >
      {cookiePreferencesCopy.cookieOptions.map(
        (cookiePref: CookiePreference) => {
          const { name, description, displayName, ...props } = cookiePref;
          return (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => (
                <CustomCheckbox
                  onChange={(e) => {
                    // ifs required we dont want the value to be able to change
                    // so no op
                    props.required ? undefined : onChange(e.target.checked);
                  }}
                  allowChange={!props.required}
                  checked={value}
                  key={name}
                  id={name}
                  name={name}
                  {...props}
                >
                  <div>
                    <div>{displayName}</div>
                    <div>{description}</div>
                  </div>
                </CustomCheckbox>
              )}
            />
          );
        },
      )}
      <AcceptPreferencesButton />
    </form>
  );
};

export default CookiePreferences;
