import * as React from "react";
import type { SVGProps } from "react";
const SvgNull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.5 18.44 5.56 4.5 4.5 5.56l1.7 1.7A7.442 7.442 0 0 0 4.5 12c0 4.14 3.36 7.5 7.5 7.5 1.8 0 3.45-.64 4.74-1.7l1.7 1.7 1.06-1.06ZM12 18c-3.31 0-6-2.69-6-6 0-1.38.48-2.65 1.27-3.67l8.4 8.4A5.977 5.977 0 0 1 12 18ZM12 6c3.31 0 6 2.69 6 6 0 .83-.17 1.62-.48 2.34l1.12 1.12c.54-1.04.85-2.21.85-3.46 0-4.14-3.36-7.5-7.5-7.5-1.25 0-2.43.31-3.46.85l1.12 1.12C10.38 6.17 11.17 6 12 6Z"
    />
  </svg>
);
export default SvgNull;
