import * as React from "react";
import type { SVGProps } from "react";
const SvgEllipsis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.5 14.25a2.25 2.25 0 1 1 .002-4.502A2.25 2.25 0 0 1 4.5 14.25ZM12 14.25a2.25 2.25 0 1 1 .002-4.502A2.25 2.25 0 0 1 12 14.25ZM19.5 14.25a2.25 2.25 0 1 1 .002-4.502 2.25 2.25 0 0 1-.002 4.502Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEllipsis;
