import axios from "axios";
import { CONTENTFUL_BASE_URL } from "./constants";
import { DropdownMenu } from "nvs-types/dist/types/generated/contentful";

const GLOBAL_NAVIGATION_API = `${CONTENTFUL_BASE_URL}/global-nav-dropdown-menus/`;

export const makeGlobalNavDropdownMenusApiCall = async () => {
  return await axios.get(GLOBAL_NAVIGATION_API).then(({ data }) => {
    const dataItems =
      data.globalNavigationCollection.items[0].globalNavigationItemsCollection
        .items;
    return dataItems as DropdownMenu[];
  });
};
