import UAParser from "ua-parser-js";
import { Country } from "../geo/countries";
import { User } from "../schema/user";
import { isClient } from "../utils";

//TODO: Will be depricated to nvs-analytics
//TODO: lets move this to the analytics context since it's only used in globalProperties()?
if (isClient()) {
  const uaParser = UAParser(window.navigator.userAgent);

  // eslint-disable-next-line no-var
  var analytics = window.analyticsClient;
  // eslint-disable-next-line no-var
  var os = {
    name: uaParser.os.name,
    version: uaParser.os.version,
  };
}
//TODO: Will be depricated to nvs-analytics
export const getPageName = (
  experienceType: string,
  pageType: string,
  pageDetail?: string, // Optional
) =>
  [experienceType, pageType, pageDetail]
    .filter((x) => x)
    .join(">")
    .toLowerCase();
//TODO: Will be depricated to nvs-analytics
export const SCHEMA_LINK = (
  CLASSIFICATION_TYPE: string,
  EXPERIENCE_NAME: string,
  EVENT_TYPE: string,
  EVENT_NAME: string,
) => {
  const link =
    "https://www.nike.com/assets/measure/schemas/digital-product/nvs/platform/web/classification/" +
    CLASSIFICATION_TYPE +
    "/experience/" +
    EXPERIENCE_NAME +
    "/event-type/" +
    EVENT_TYPE +
    "/event-name/" +
    EVENT_NAME +
    "/version/LATEST/schema.json";
  return link;
};

//TODO: Will be depricated to nvs-analytics
export const globalProperties = (
  country: Partial<Country> | string | undefined,
  user: Partial<User> | undefined,
) => {
  const loginStatus = user !== undefined && JSON.stringify(user) !== "{}";
  let membershipStatus;
  loginStatus ? (membershipStatus = "active") : (membershipStatus = "inactive");
  return {
    anonymousId: analytics?._anonymousId,
    context: {
      // ip: "",
      os: os,
    },
    consumer: {
      upmId: user?.upmId || "guest",
      optimizelyUserId: user?.upmId || "guest",
      swooshHandle: user?.swooshHandle || "guest",
      swooshMemberNumber:
        user?.membershipCard?.memberNumber.toString() || "guest",
      swooshMembershipStatus: membershipStatus,
      loginStatus: loginStatus,
    },
    locale: {
      country: country,
    },
  };
};

export const swooshIdProperties = (user: Partial<User> | undefined) => {
  return {
    color: user?.membershipCard?.color,
    logo: user?.membershipCard?.bottomLogo,
  };
};
