import * as React from "react";
import type { SVGProps } from "react";
const SvgShuffle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m17.28 18.4 2.65-2.65-2.65-2.65M17.28 10.9l2.65-2.65-2.65-2.65"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M19.5 8.25h-4.14c-.62 0-1.18.25-1.59.66l-6.18 6.18c-.41.41-.97.66-1.59.66H3M9.09 10.41l-1.5-1.5c-.41-.41-.97-.66-1.59-.66H3M19.5 15.75h-4.14c-.62 0-1.18-.25-1.59-.66l-1.5-1.5"
    />
  </svg>
);
export default SvgShuffle;
