import clsx from "clsx";
import React from "react";
import OnboardingCustomButton, {
  OnboardingCustomButtonProps,
} from "../OnboardingCustomButton";

// DEPRECATED - Do not use this.
// Use the <Button> at src/button/index.tsx instead.

type Theme = "dark" | "light";

type Style = "inverse" | "primary";

type Props = OnboardingCustomButtonProps & {
  style?: Style;
};

const buttonStyling = {
  inverse:
    "text-black bg-black border-black betterhover:hover:text-cream betterhover:hover:border-primary betterhover:hover:bg-gray-950",
  primary:
    "text-cream bg-gray-950 border-primary betterhover:hover:text-black betterhover:hover:border-black betterhover:hover:bg-black",
};

const OnboardingSecondaryButton: React.FC<Props> = ({
  style = "primary",
  classes,
  ...props
}) => {
  return (
    <OnboardingCustomButton
      {...props}
      classes={clsx(buttonStyling[style], classes, "border")}
    ></OnboardingCustomButton>
  );
};

export default OnboardingSecondaryButton;
