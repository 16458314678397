import * as React from "react";
import type { SVGProps } from "react";
const SvgShopSnkrs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.39 14.31c-.96-.71-.23-2.12.03-2.54-.47.52-.94 1.03-1.3 1.59-.57.88-.82 1.85-.43 2.47.7 1.12 2.37.6 3.43.15L21 11.81l-9.76 2.58c-.82.21-1.48.19-1.85-.08Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M23.25 4.5v12c0 1.24-1.01 2.25-2.25 2.25H3c-1.24 0-2.25-1.01-2.25-2.25v-1.93l8.76-5.06c.17-.1.33-.22.47-.36l3.9-3.9h4.13c1.24 0 2.25 1.01 2.25 2.25V9"
    />
  </svg>
);
export default SvgShopSnkrs;
