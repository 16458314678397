import * as React from "react";
import type { SVGProps } from "react";
const SvgRfid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8.29 15.71a5.245 5.245 0 0 1 0-7.42M15.71 8.29a5.245 5.245 0 0 1 0 7.42M6.17 17.83c-3.22-3.22-3.22-8.45 0-11.67M17.83 6.17c3.22 3.22 3.22 8.45 0 11.67M12 14.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
    />
  </svg>
);
export default SvgRfid;
