export enum OptimizelyFeatureFlags {
  ONBOARDING = "onboarding",
  LANDING_PAGE = "landingpage",
  ADMIN = "admin",
  DESIGN_SYSTEM = "design_system",
  UTILITY_REDEMPTION_CTA = "utility_redemption_cta",
  RTFKT_LOGOS_ENABLED = "rtfkt_logos_enabled",
  MARKETPLACE = "marketplace",
  ALCHEMY = "alchemy",
  VERIFIED_PROFILE = "verified_profile",
  REVEAL = "reveal",
  GALLERY = "gallery",
  ACCOUNT_LINKING = "account_linking",
  CLAIM_ACHIEVEMENTS = "claim_achievements",
  UAT_OVERRIDE = "uat_override",
  PROFILE_SUBDOMAINS = "profile_subdomains",
  CMS_3D_AUTHORING_TLP = "cms_3d_authoring_tlp",
  LANDING_PAGE_MARKETING_BANNER = "landing_page_marketing_banner",
  CDP_REDESIGN = "cdp_redesign",
  MULTI_UNIT_CHECKOUT = "multi_unit_checkout",
  PREORDER_PILOT = "preorder_pilot",
  PREORDER_SHOPIFY_INTEGRATED = "preorder_shopify_integrated",
  _404_ERROR_MARKETING_PAGE = "404_error_marketing_page",
  _404_ERROR_MARKETING_PAGE_LENTICULAR = "404_error_marketing_page_lenticular",
  ONBOARDING_V_1_5 = "onboarding_v_1_5",
  ONBOARDING_NFT_PAGES = "onboarding_nft_pages",
  GLOBAL_NAVIGATION_2_0 = "global_nav_2_0",
  TOKEN_TRANSFER = "token_transfer",
  PREORDER_NARVAR_TRACKING = "preorder_tracking_narvar",
  LOW_POLY_REVEAL = "low-poly-reveal",
  LOW_POLY_PRE_ORDER = "low_poly_pre_order",
  PHYSICAL_PRODUCT_3D = "physical_product_3d",
  // lets keep this flag always last
  TEST_FLAG = "test_flag", // feature flag used to test feature flags on/off - not used
  MAXS_LAB_MARKETING_PAGE = "maxs_lab_marketing_page",
  HOME_PAGE_REFRESH_24 = "home_page_refresh_24",
  SWOOSH_HANDLE_AUTOGENERATION = "swoosh_handle_autogeneration",
}
