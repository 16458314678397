import * as React from "react";
import type { SVGProps } from "react";
const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3a4.5 4.5 0 0 0-4.5 4.5H9a3 3 0 0 1 3-3V3ZM7.5 7.5A4.5 4.5 0 0 0 12 12v-1.5a3 3 0 0 1-3-3H7.5ZM12 12a4.5 4.5 0 0 0 4.5-4.5H15a3 3 0 0 1-3 3V12Zm4.5-4.5A4.5 4.5 0 0 0 12 3v1.5a3 3 0 0 1 3 3h1.5ZM4.5 21v-3H3v3h1.5Zm0-3a3 3 0 0 1 3-3v-1.5A4.5 4.5 0 0 0 3 18h1.5Zm3-3h9v-1.5h-9V15Zm9 0a3 3 0 0 1 3 3H21a4.5 4.5 0 0 0-4.5-4.5V15Zm3 3v3H21v-3h-1.5Z"
    />
  </svg>
);
export default SvgProfile;
