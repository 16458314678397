import clsx from "clsx";

import { BodyName, BodyProps } from "./types";

export const MAP_BODY_NAME_TO_STYLE = {
  [BodyName.Body_1]: "text-7xl leading-120 font-normal",
  [BodyName.Body_2]: "text-6xl leading-120 font-normal",
  [BodyName.Body_3]: "text-5xl leading-120 font-normal",
  [BodyName.Body_4]: "text-xl leading-140 font-normal",
  [BodyName.Body_5]: "text-md leading-140 font-normal",
  [BodyName.Body_6]: "text-base leading-140 font-medium",
  [BodyName.Body_7]: "text-base leading-140 font-normal",
  [BodyName.Body_8]: "text-sm leading-150 font-normal",
  [BodyName.Body_9]: "text-sm leading-150 font-medium",
};

// NOTE: This is basically duplicating the above styles with a breakpoint prefix
// Do not try to DRY this up - generating Tailwind classes dynamically
// will result in the being excluded from the final CSS bundle.
// They must be able to be statically analyzed.

export const MAP_BODY_NAME_TO_MD_BREAKPOINT_STYLE = {
  [BodyName.Body_1]: "md:text-7xl md:leading-120 md:font-normal",
  [BodyName.Body_2]: "md:text-6xl md:leading-120 md:font-normal",
  [BodyName.Body_3]: "md:text-5xl md:leading-120 md:font-normal",
  [BodyName.Body_4]: "md:text-xl md:leading-140 md:font-normal",
  [BodyName.Body_5]: "md:text-md md:leading-140 md:font-normal",
  [BodyName.Body_6]: "md:text-base md:leading-140 md:font-medium",
  [BodyName.Body_7]: "md:text-base md:leading-140 md:font-normal",
  [BodyName.Body_8]: "md:text-sm md:leading-150 md:font-normal",
  [BodyName.Body_9]: "md:text-sm md:leading-150 md:font-medium",
};

/**
 * NOTE: exporting this in case we need to set the
 * styles on a non-subhead element/component
 */
export function getBodyStyles(props: Omit<BodyProps, "as">) {
  return clsx(
    "font-roobert",
    MAP_BODY_NAME_TO_STYLE[props.name],
    // Optional breakpoint styles
    props.md && MAP_BODY_NAME_TO_MD_BREAKPOINT_STYLE[props.md],
    props.className,
  );
}
