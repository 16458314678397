import { clsx } from "clsx";

type DividerProps = { className?: string };

const Divider: React.FC<DividerProps> = ({ className }) => (
  <hr
    className={clsx(
      "w-full border-t border-gray-600 bg-transparent",
      className,
    )}
  />
);

export default Divider;
