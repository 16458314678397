import * as React from "react";
import type { SVGProps } from "react";
const SvgGlobal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 21.75c5.385 0 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25 2.25 6.615 2.25 12s4.365 9.75 9.75 9.75Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 21.75c2.9 0 5.25-4.365 5.25-9.75S14.9 2.25 12 2.25 6.75 6.615 6.75 12 9.1 21.75 12 21.75Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 15.75c5.385 0 9.75-1.679 9.75-3.75 0-2.071-4.365-3.75-9.75-3.75S2.25 9.929 2.25 12c0 2.071 4.365 3.75 9.75 3.75Z"
    />
  </svg>
);
export default SvgGlobal;
