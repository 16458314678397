import { AxiosError } from "axios";
import { GLOBAL_NAV_HEADERS } from "@/libs/apis/onboarding/reactQueryCacheKeys";
import { GlobalNavigationItemsItem } from "nvs-types/dist/types/generated/contentful";
import { useQuery } from "@tanstack/react-query";
import { makeGlobalNavHeadersDataApiCall } from "../services/api/getGlobalNavHeadersData";

export const useGlobalNavHeadersData = () => {
  const query = useQuery<
    GlobalNavigationItemsItem[],
    AxiosError,
    GlobalNavigationItemsItem[]
  >([GLOBAL_NAV_HEADERS], () => makeGlobalNavHeadersDataApiCall(), {
    retry: false,
    refetchOnMount: true,
    enabled: true,
  });

  return query;
};
