import * as React from "react";
import type { SVGProps } from "react";
const SvgLocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12 21.747-.468.586a.75.75 0 0 0 .936 0L12 21.747ZM18 9c0 .92-.303 2.081-.824 3.362-.515 1.269-1.222 2.6-1.974 3.843a38.411 38.411 0 0 1-2.217 3.274c-.695.914-1.223 1.498-1.453 1.682l.936 1.172c.395-.314 1.023-1.042 1.711-1.947a39.904 39.904 0 0 0 2.306-3.404c.78-1.288 1.526-2.691 2.08-4.055.55-1.352.935-2.723.935-3.927H18Zm-5.532 12.16c-.23-.183-.758-.767-1.453-1.681a38.41 38.41 0 0 1-2.217-3.274c-.752-1.243-1.458-2.574-1.974-3.843C6.303 11.081 6 9.921 6 9H4.5c0 1.204.385 2.575.934 3.927.555 1.364 1.302 2.767 2.08 4.055.78 1.288 1.6 2.474 2.307 3.404.688.905 1.316 1.633 1.711 1.947l.936-1.172ZM6 9a6 6 0 0 1 6-6V1.5A7.5 7.5 0 0 0 4.5 9H6Zm6-6a6 6 0 0 1 6 6h1.5A7.5 7.5 0 0 0 12 1.5V3Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.25 9a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPin;
