import React, { useState } from "react";
import CookieAcceptView from "./CookieAcceptView";
import CookiePreferences from "./CookiePreferences";
import { cookieFinePrint } from "../copy";
import { CookiePreferences as CookieSettings } from "@/libs/schema/user";
import LegalAgreementLink, {
  AgreementType,
} from "@/src/components/Link/LegalAgreementLink";
import ModalDivider from "@/src/components/Modal/ModalDivider";

type Props = {
  onAccept: (cookieSettings: CookieSettings) => void;
  cookieSettings: CookieSettings | null;
};

const defaultPreferences = {
  functional: true,
  performance: false,
};

const CookieBannerContent: React.FC<Props> = ({ cookieSettings, onAccept }) => {
  const [showPreferencesView, setShowPreferencesView] =
    useState<boolean>(false);
  const [localCookiePreferences, setLocalCookiePreferences] =
    useState<CookieSettings>(cookieSettings ?? defaultPreferences);

  return (
    <div className="leading-120 laptop:gap-6 mt-4 grid flex-col justify-evenly gap-8">
      {showPreferencesView ? (
        <CookiePreferences
          initialValues={localCookiePreferences}
          onSubmit={(data) => {
            setLocalCookiePreferences({ ...data });
            onAccept({ ...data });
          }}
        />
      ) : (
        <CookieAcceptView
          onMoreInfo={() =>
            setShowPreferencesView(
              (showPreferencesView) => !showPreferencesView,
            )
          }
          // Default view makes accepted
          onAccept={() => onAccept({ functional: true, performance: true })}
        />
      )}
      <ModalDivider />
      <div className="text-sm">
        {cookieFinePrint}
        &nbsp;View{" "}
        <LegalAgreementLink agreementType={AgreementType.PrivacyPolicy} /> for
        full details.
      </div>
    </div>
  );
};

export default CookieBannerContent;
