import GlobalFooterView from "./components/GlobalFooterView";
import { FooterProps } from "../types";
import { CookieBannerController } from "../CookieWall/components/CookieBannerController";

type Props = Omit<FooterProps, "setOpenCookieBanner">;

const GlobalFooter: React.FC<Props> = ({ className, country }) => {
  return (
    <CookieBannerController country={country}>
      {(controller) => (
        <GlobalFooterView
          country={country}
          setOpenCookieBanner={controller.setIsOpen}
          className={className}
        />
      )}
    </CookieBannerController>
  );
};

export default GlobalFooter;
