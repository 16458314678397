import * as React from "react";
import type { SVGProps } from "react";
const SvgProfileSwoosh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3a4.5 4.5 0 0 0-4.5 4.5H9a3 3 0 0 1 3-3V3ZM7.5 7.5A4.5 4.5 0 0 0 12 12v-1.5a3 3 0 0 1-3-3H7.5ZM12 12a4.5 4.5 0 0 0 4.5-4.5H15a3 3 0 0 1-3 3V12Zm4.5-4.5A4.5 4.5 0 0 0 12 3v1.5a3 3 0 0 1 3 3h1.5ZM4.5 21v-3H3v3h1.5Zm0-3a3 3 0 0 1 3-3v-1.5A4.5 4.5 0 0 0 3 18h1.5Zm3-3H17v-1.5H7.5V15Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.5 17-8.41 3.568c-.7.297-1.29.445-1.764.445-.534 0-.923-.189-1.162-.565-.31-.485-.175-1.266.357-2.09.316-.482.717-.924 1.108-1.347-.092.15-.904 1.5-.016 2.137.176.128.425.19.733.19.246 0 .53-.04.84-.121L22.5 17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgProfileSwoosh;
