import "external-svg-loader";
import "../styles/globals.css";
import "../styles/toast/scss/main.scss";
import type { AppProps } from "next/app";
import { NextPageWithLayoutProps, PageProps } from "@/src/types/next";
import DocumentHeadDefaults from "@/src/components/DocumentHeadDefaults";
import AppErrorBoundary from "@/src/components/AppErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import { SessionProvider } from "next-auth/react";
import QueryClientProviderWrapper from "@/src/components/QueryClientProviderWrapper";
import OptimizelyProviderWrapper from "@/src/services/optimizely/OptimizelyProviderWrapper";
import { GeoLocProvider } from "@/libs/geo/GeoLocProvider";
import { RouteProgress } from "@/src/components/RouteProgress";
import { fetchOptimizelyClientData } from "@/app/lib/services/optimizely/client/fetchOptimizelyConfig";

type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayoutProps<PageProps>;
};

function App({ Component, pageProps, router }: AppPropsWithLayout) {
  const { dehydratedState } = pageProps;
  const dataFromOptimizely = fetchOptimizelyClientData();

  const getLayout =
    Component.getLayout ??
    ((page) => (
      <ErrorBoundary FallbackComponent={AppErrorBoundary}>
        <DocumentHeadDefaults />
        {page}
      </ErrorBoundary>
    ));
  return (
    <SessionProvider>
      {/* can also pass in region, timezone, latitude, longitude if needed at some point */}
      <GeoLocProvider>
        {/* Optimizely must be above the React Query Client because we enable queries based on optimizely flags */}
        <OptimizelyProviderWrapper dataFromOptimizely={dataFromOptimizely}>
          <QueryClientProviderWrapper dehydratedState={dehydratedState}>
            <RouteProgress />
            {getLayout(
              <Component key={router.pathname} {...pageProps} />,
              pageProps,
            )}
          </QueryClientProviderWrapper>
        </OptimizelyProviderWrapper>
      </GeoLocProvider>
    </SessionProvider>
  );
}
export default App;
