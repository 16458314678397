import * as React from "react";
import type { SVGProps } from "react";
const SvgPlan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M19.5 21.75h-15c-1.24 0-2.25-1.01-2.25-2.25V6c0-1.24 1.01-2.25 2.25-2.25h15c1.24 0 2.25 1.01 2.25 2.25v13.5c0 1.24-1.01 2.25-2.25 2.25ZM21.75 8.25H2.25M8.25 1.5V6M15.75 1.5V6"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.05 16.97 4.42-4.42M14.05 12.55l4.42 4.42M10.9 12.49l-3.42 3.92-1.95-1.94"
    />
  </svg>
);
export default SvgPlan;
