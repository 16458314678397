import * as React from "react";
import type { SVGProps } from "react";
const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m14.98 11.795 1.354 1.354c.098-.37.167-.75.167-1.149 0-2.481-2.02-4.5-4.5-4.5-.4 0-.78.069-1.15.167l1.355 1.354a2.986 2.986 0 0 1 2.774 2.774M12 18c-4.37 0-7.979-4.594-8.982-5.998.568-.798 1.981-2.611 3.886-4.039l1.437 1.436A4.443 4.443 0 0 0 7.501 12c-.001 2.481 2.018 4.5 4.5 4.5a4.44 4.44 0 0 0 2.6-.842l1.224 1.225C14.661 17.545 13.37 18 12 18Zm-2.577-7.52 4.096 4.097A2.938 2.938 0 0 1 12 15c-1.654 0-3-1.346-3-3 0-.557.156-1.074.423-1.52Zm8.744 6.624-1.06-1.061-1.446-1.444v-.001l-1.085-1.085-4.089-4.089L9.402 8.34 8.175 7.112h.001L7.093 6.029 4.06 2.998 3 4.06l2.844 2.843c-1.999 1.537-3.451 3.391-4.048 4.23a1.495 1.495 0 0 0 0 1.735C2.903 14.424 6.912 19.5 12 19.5c1.8 0 3.461-.64 4.911-1.531l3.03 3.029L21 19.937l-2.834-2.833Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.213 11.131C21.103 9.575 17.086 4.5 12 4.5c-1.248 0-2.43.309-3.523.793l1.146 1.146A6.945 6.945 0 0 1 12 6c4.367 0 7.984 4.59 8.99 6a20.26 20.26 0 0 1-2.725 3.08l1.056 1.057a21.717 21.717 0 0 0 2.892-3.268 1.5 1.5 0 0 0 0-1.738"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHide;
