import * as React from "react";
import type { SVGProps } from "react";
const SvgOrders = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.25 3.75C13.01 3.75 12 4.76 12 6.5v7M20.25 9.75H3.75"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.25 3.75h4.39l1.61 6v10.5H3.75V9.75l1.61-6h5.14"
    />
  </svg>
);
export default SvgOrders;
