/**
 * Create a route with params replaced with values.
 * Params must be wrapped in square brackets like the Next.js page routing.
 *
 * Example:
 * createRouteFromParams("collections/[collectionName]", { collectionName: "our-force-1" })
 *
 * @param route - The route constant to create from.
 * @param params - The object of params to add to the route. Keys will be used to match. Values will be used to replace.
 */

type Params = Record<string, string>;

export function createRouteFromParams(route: string, params: Params) {
  for (const key of Object.keys(params)) {
    route = route.replace(`[${key}]`, params[key]);
  }
  return route;
}
