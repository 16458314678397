import * as React from "react";
import type { SVGProps } from "react";
const SvgDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.51 20.25h9M13.51 17.25h6c1.24 0 2.25-1.01 2.25-2.25V6c0-1.24-1.01-2.25-2.25-2.25h-15C3.27 3.75 2.26 4.76 2.26 6v9c0 1.24 1.01 2.25 2.25 2.25h5c1.24 0 2.25 1.01 2.25 2.25v.75"
    />
  </svg>
);
export default SvgDesktop;
