import * as React from "react";
import type { SVGProps } from "react";
const SvgTranslation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 18.75H.75V5.25H12M12 5.25h11.25v13.5H12"
    />
    <path
      fill="currentColor"
      d="M6.68 8.25h1.77l2.65 7.5H9.4l-.49-1.54H6.15l-.51 1.54H4l2.68-7.5Zm-.11 4.67h1.92l-.94-2.96-.98 2.96Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18 10c0 .746-.183 1.461-.514 2.108a5.516 5.516 0 0 1-1.403 1.728A6.672 6.672 0 0 1 14 15"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M15.014 12.108c.33.647.807 1.233 1.403 1.728.597.494 1.294.89 2.083 1.164M16.25 8.25V10"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M12.5 10H20" />
  </svg>
);
export default SvgTranslation;
