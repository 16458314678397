import * as React from "react";
import type { SVGProps } from "react";
const SvgTabletVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M16.51 21.75h-9c-1.24 0-2.25-1.01-2.25-2.25v-15c0-1.24 1.01-2.25 2.25-2.25h9c1.24 0 2.25 1.01 2.25 2.25v15c0 1.24-1.01 2.25-2.25 2.25ZM10.5 18.75h3"
    />
  </svg>
);
export default SvgTabletVertical;
