import GlobalFooter from "@/src/components/GlobalElements/GlobalFooter";
import { GridBackground } from "@/src/components/grid/components/GridBackground";
import { SiteWrapper } from "@/src/components/grid/components/SiteWrapper";
import GlobalHeader from "@/src/components/GlobalElements/GlobalHeader";
import BaseLayout from "./BaseLayout";
import { Country } from "@/libs/geo/countries";
import { useGeoLoc } from "@/libs/geo/GeoLocProvider";
import { DarkThemeStyle } from "./DarkThemeStyle";

type GridLayoutProps = React.PropsWithChildren;

const GridLayout = ({ children }: GridLayoutProps) => {
  const { country } = useGeoLoc();

  return (
    <BaseLayout>
      <DarkThemeStyle />
      <GridBackground className="font-roobert flex min-h-full w-full flex-col justify-between">
        <GlobalHeader />
        <SiteWrapper
          className="flex flex-grow flex-col"
          innerClassName="flex flex-grow flex-col"
        >
          {children}
        </SiteWrapper>

        <GlobalFooter country={country as Country} />
      </GridBackground>
    </BaseLayout>
  );
};

export default GridLayout;
