import * as React from "react";
import type { SVGProps } from "react";
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m12.53 16.773-2.12 2.121a3.752 3.752 0 0 1-5.305-5.304l3.182-3.18a3.75 3.75 0 0 1 5.304 0l1.061 1.06"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m11.47 7.227 2.12-2.122a3.752 3.752 0 0 1 5.305 5.304l-3.182 3.182a3.75 3.75 0 0 1-5.304 0l-1.061-1.06"
    />
  </svg>
);
export default SvgLink;
