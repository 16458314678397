import clsx from "clsx";
import DotSwooshWordmarkSvg from "./DotSwooshWordmarkSvg";
import LeftFooter from "./LeftFooter";
import RightFooter from "./RightFooter";
import Divider from "./Divider";
import { FooterProps } from "../../types";
import { SiteWrapper } from "../../../grid/components/SiteWrapper";

const GlobalFooterView: React.FC<FooterProps> = ({
  className,
  country,
  setOpenCookieBanner,
}) => {
  return (
    <footer
      className={clsx(
        "font-roobert bg-gray-950 flex w-full justify-center border-t border-gray-700 z-footer",
        className,
      )}
    >
      <SiteWrapper className="laptop:pt-11 laptop:pb-4 py-8">
        <div className="3xl:items-center flex w-full flex-col">
          <div className="1440:justify-center relative flex w-full flex-col gap-36">
            <DotSwooshWordmarkSvg />
            <div className="1440:flex-row flex w-full flex-col justify-between gap-9 text-sm text-white">
              <LeftFooter />
              <Divider className="1440:hidden" />
              <RightFooter
                country={country}
                setOpenCookieBanner={setOpenCookieBanner}
              />
            </div>
          </div>
        </div>
      </SiteWrapper>
    </footer>
  );
};

export default GlobalFooterView;
