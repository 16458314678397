import clsx from "clsx";
import { HeadlineName, HeadlineProps } from "./types";

export const MAP_HEADLINE_NAME_TO_STYLE = {
  [HeadlineName.Headline_1]: "text-10xl",
  [HeadlineName.Headline_2]: "text-9xl",
  [HeadlineName.Headline_3]: "text-8xl",
  [HeadlineName.Headline_4]: "text-7xl",
  [HeadlineName.Headline_5]: "text-6xl",
  [HeadlineName.Headline_6]: "text-5xl",
};

// NOTE: This is basically duplicating the above styles with a breakpoint prefix
// Do not try to DRY this up - generating Tailwind classes dynamically
// will result in the being excluded from the final CSS bundle.
// They must be able to be statically analyzed.

export const MAP_HEADLINE_NAME_TO_MD_BREAKPOINT_STYLE = {
  [HeadlineName.Headline_1]: "md:text-10xl",
  [HeadlineName.Headline_2]: "md:text-9xl",
  [HeadlineName.Headline_3]: "md:text-8xl",
  [HeadlineName.Headline_4]: "md:text-7xl",
  [HeadlineName.Headline_5]: "md:text-6xl",
  [HeadlineName.Headline_6]: "md:text-5xl",
};

/**
 * NOTE: exporting this in case we need to set the
 * styles on a non-headline element/component
 */
export function getHeadlineStyles(props: Omit<HeadlineProps, "as">) {
  return clsx(
    "font-roobert font-medium uppercase",
    MAP_HEADLINE_NAME_TO_STYLE[props.name],
    props.className,
    props.leading ?? "leading-none",
    // Optional breakpoint styles
    props.md && MAP_HEADLINE_NAME_TO_MD_BREAKPOINT_STYLE[props.md],
  );
}
