import clsx from "clsx";
import { TitleName, TitleProps } from "./types";

export const MAP_TITLE_NAME_TO_TEXT_SIZE = {
  [TitleName.Title_7]: "text-base leading-120",
  [TitleName.Title_6]: "text-xl leading-120",
  [TitleName.Title_5]: "text-2xl leading-120",
  [TitleName.Title_4]: "text-3xl leading-120",
  [TitleName.Title_3]: "text-5xl leading-120",
  [TitleName.Title_2]: "text-6xl leading-120",
  [TitleName.Title_1]: "text-8xl leading-none",
};

// NOTE: This is basically duplicating the above styles with a breakpoint prefix
// Do not try to DRY this up - generating Tailwind classes dynamically
// will result in the being excluded from the final CSS bundle.
// They must be able to be statically analyzed.
export const MAP_TITLE_NAME_TO_MD_BREAKPOINT_TEXT_SIZE = {
  [TitleName.Title_7]: "md:text-base md:leading-120",
  [TitleName.Title_6]: "md:text-xl md:leading-120",
  [TitleName.Title_5]: "md:text-2xl md:leading-120",
  [TitleName.Title_4]: "md:text-3xl md:leading-120",
  [TitleName.Title_3]: "md:text-5xl md:leading-120",
  [TitleName.Title_2]: "md:text-6xl md:leading-120",
  [TitleName.Title_1]: "md:text-8xl md:leading-none",
};

/**
 * NOTE: exporting this in case we need to set the
 * styles on a non-title element/component
 */
export function getTitleStyles(props: Omit<TitleProps, "as">) {
  return clsx(
    "font-roobert font-bold uppercase",
    MAP_TITLE_NAME_TO_TEXT_SIZE[props.name],
    props.className,
    // Optional breakpoint styles
    props.md && MAP_TITLE_NAME_TO_MD_BREAKPOINT_TEXT_SIZE[props.md],
  );
}
