import * as React from "react";
import type { SVGProps } from "react";
const SvgMenu2Lines = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M3 8.25h18M3 15.75h15" />
  </svg>
);
export default SvgMenu2Lines;
