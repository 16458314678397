import * as React from "react";
import type { SVGProps } from "react";
const SvgExperiments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.5 3.75h9M9.75 4.25v5.89c0 .6-.24 1.17-.66 1.59l-4.68 4.68C3 17.83 4 20.25 6 20.25h12c2 0 3.01-2.42 1.59-3.84l-4.68-4.68c-.42-.42-.66-.99-.66-1.59V4M6.5 14.25h11.12"
    />
  </svg>
);
export default SvgExperiments;
