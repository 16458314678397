import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import { NikeUser } from "../types";

export const convertSessionUserToNikeUser = (
  session: Session | null,
): NikeUser | undefined => {
  if (!session || session === null || !session.user || session.user === null)
    return undefined;

  const { name, email, upmId } = session.user;

  if (!name || name === null || !email || email === null || upmId === undefined)
    return undefined;

  const [firstName, lastName] = name.split(",");
  return { firstName, lastName, email, upmId };
};

const useCurrentNikeUser = (): NikeUser | undefined => {
  const { data: session, status } = useSession();
  if (status === "unauthenticated" || status === "loading") return undefined;

  const nikeUser = convertSessionUserToNikeUser(session);
  return nikeUser;
};

export default useCurrentNikeUser;
