import * as React from "react";
import type { SVGProps } from "react";
const SvgNikeMembership = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7.5 14v-.5A2.5 2.5 0 0 1 10 11h4a2.5 2.5 0 0 1 2.5 2.5v.5M14 6.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6 16h12M6 19h8"
    />
    <rect
      width={16.5}
      height={20}
      x={3.75}
      y={2}
      stroke="currentColor"
      strokeWidth={1.5}
      rx={2}
    />
  </svg>
);
export default SvgNikeMembership;
