import { ROUTE_CREATE_USER, ROUTE_HOME } from "@/constants/routes";
import { usePathname } from "next/navigation";

export const useCreateCallbackUrl = (path?: string) => {
  const pathName = usePathname();

  const callbackPath = path ?? pathName ?? ROUTE_HOME;
  return `${process.env.NEXT_PUBLIC_SITE_URL}${callbackPath}`;
};

export const useCreateSignInCallbackUrl = (path?: string) => {
  const defaultPathname = usePathname();
  const callBackPath = ROUTE_CREATE_USER + (path ?? defaultPathname);
  const callBackUrl = useCreateCallbackUrl(callBackPath);

  return callBackUrl;
};
