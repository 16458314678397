import { AxiosError } from "axios";
import { GLOBAL_NAV_DROPDOWN_MENUS } from "@/libs/apis/onboarding/reactQueryCacheKeys";
import { ERROR_CODES } from "@/libs/error/types";
import {
  DropdownMenu,
  GlobalNavigation,
} from "nvs-types/dist/types/generated/contentful";
import { useQuery } from "@tanstack/react-query";
import { makeGlobalNavDropdownMenusApiCall } from "../services/api/getGlobalNavDropdownMenusData";

export const useGlobalNavDropdownMenusData = (enabled: boolean) => {
  const query = useQuery<DropdownMenu[], AxiosError, DropdownMenu[]>(
    [GLOBAL_NAV_DROPDOWN_MENUS],
    () => makeGlobalNavDropdownMenusApiCall(),
    {
      retry: false,
      refetchOnMount: false,
      enabled: enabled,
      useErrorBoundary: ({ response }) =>
        response !== undefined &&
        response?.status >= ERROR_CODES.INTERNAL_ERROR,
    },
  );

  return query;
};
