import { useLocalStorageValue } from "@react-hookz/web";

const GLOBAL_BANNER_DISMISSED_STORAGE_KEY = "status-banner-dismissed";

export function getStorageKey(id?: string) {
  return `${GLOBAL_BANNER_DISMISSED_STORAGE_KEY}-${id}`;
}

type UseStatusBannerStorage = {
  initialIsDismissed?: boolean | null;
  id?: string;
};

export function useStatusBannerStorage(config: UseStatusBannerStorage) {
  const [isDismissed, setValue] = useLocalStorageValue<boolean | null>(
    getStorageKey(config.id),
    config.initialIsDismissed,
  );

  function setDismissed() {
    setValue(true);
  }

  return { isDismissed, setDismissed, setValue };
}
