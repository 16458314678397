import clsx from "clsx";
import { PropsWithChildren } from "react";

type SiteMaxWidthProps = PropsWithChildren<{
  className?: string;
}>;

export function SiteMaxWidth(props: SiteMaxWidthProps) {
  return (
    <div
      className={clsx(
        "relative w-full max-w-screen-3xl mx-auto",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}
