import * as React from "react";
import type { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.248 10.125a3.75 3.75 0 1 1-6.495 3.75 3.75 3.75 0 0 1 6.495-3.75Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.729 5.857h3.1l1.406 2.434-1.55 2.685a2.25 2.25 0 0 0 0 2.25l1.433 2.482-1.406 2.434h-2.866a2.25 2.25 0 0 0-1.95 1.125l-1.432 2.483h-2.81L9.22 19.267a2.25 2.25 0 0 0-1.95-1.125h-3.1L2.766 15.71l1.55-2.687a2.25 2.25 0 0 0 0-2.25L2.883 8.291l1.406-2.434h2.866a2.25 2.25 0 0 0 1.949-1.125l1.433-2.482h2.81l1.433 2.482a2.25 2.25 0 0 0 1.949 1.125Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSettings;
