import React from "react";
import clsx from "clsx";
import ArrowSvg from "./components/ArrowSvg";

// DEPRECATED - Do not use this.
// Use the <Button> at src/button/index.tsx instead.

export type OnboardingCustomButtonProps = {
  buttonText: string;
  classes?: string;
  useArrow?: boolean;
  submitting?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
} & Pick<React.HTMLProps<HTMLButtonElement>, "disabled">;

const OnboardingCustomButton: React.FC<OnboardingCustomButtonProps> = ({
  buttonText,
  classes,
  useArrow = false,
  submitting = false,
  disabled = false,
  type = "submit",
  ...props
}: OnboardingCustomButtonProps) => {
  return (
    <button
      {...props}
      disabled={disabled}
      type={type}
      aria-describedby="btn-txt"
      className={clsx(
        "flex justify-center rounded-full font-normal",
        "group",
        "disabled:pointer-events-none disabled:border disabled:border-gray-600 disabled:bg-black disabled:text-gray-600",
        classes,
        submitting && "border border-gray-600 bg-black  text-gray-600",
      )}
    >
      <div className="flex h-3 w-full flex-row items-center justify-center">
        <div id="btn-text">{buttonText}</div>

        {(useArrow || submitting) && (
          <div className="pl-2">
            <div
              className={clsx(
                "loading",
                "opacity-50",
                !submitting && useArrow && "hidden",
                !submitting && !useArrow && "invisible",
              )}
            />
            {useArrow && !submitting && (
              <ArrowSvg
                classes={clsx(
                  disabled && "opacity-40",
                  "group-hover:text-primary",
                )}
              />
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export default OnboardingCustomButton;
