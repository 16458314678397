import * as React from "react";
import type { SVGProps } from "react";
const SvgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.75 8.25H6A2.25 2.25 0 0 1 3.75 6v12A2.25 2.25 0 0 0 6 20.25h12A2.25 2.25 0 0 0 20.25 18v-7.5A2.25 2.25 0 0 0 18 8.25h-2.25Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.75 6.5V3.75H6A2.25 2.25 0 0 0 3.75 6v1.25"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M20.25 15.75h-3a1.5 1.5 0 0 1 0-3h3v3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWallet;
