import axiosWithCredentials from "@/libs/apis/commons";
import { convertApiUserToUser } from "@/libs/mappers/user";
import { CURRENT_USER_API } from "../common";
import { User as ApiUser } from "@/libs/schema/api/user";

export const makeApiUserCall = async (config = {}) => {
  return await axiosWithCredentials
    .get(CURRENT_USER_API, config)
    .then(({ data }: { data: Partial<ApiUser> }) => {
      return convertApiUserToUser(data);
    });
};
