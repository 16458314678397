import defaultTheme from "tailwindcss/defaultTheme";

/*** External URLS ***/
export const EXTERNAL_LINK_DOT_SWOOSH_MEDIUM_BLOG = "https://blog.swoosh.nike";
export const EXTERNAL_LINK_DOT_SWOOSH_INSTAGRAM =
  "https://instagram.com/dotswoosh";
export const EXTERNAL_LINK_DOT_SWOOSH_TWITTER = "http://twitter.com/dotswoosh";
export const EXTERNAL_LINK_CONTACT_US =
  "https://www.nike.com/help/a/dot-swoosh";
export const EXTERNAL_LINK_BITGO_TOS =
  "https://www.bitgo.com/terms-of-use-wallet";
export const EXTERNAL_LINK_NIKE_ACCOUNT_SETTINGS =
  "https://www.nike.com/member/settings";

export const EXTERNAL_LINK_SWOOSH_BLOG_LEARN =
  "https://medium.com/@dotswoosh/how-to-reveal-your-of1-8a92c3824cc0";

/*** Analytics Events Keys ***/
export const LANDING_SIGN_IN_COMPLETED_SESSION_KEY = "landing-signin-clicked";
export const NAV_BAR_SIGN_COMPLETED_SESSION_KEY = "navbar-signin-clicked";
export const ONBOARDING_SIGN_IN_COMPLETED_SESSION_KEY =
  "onboarding-signin-clicked";
export const PRODUCT_ORDER_SIGN_IN_COMPLETED_SESSION_KEY =
  "product-order-signin-clicked";
export const PRODUCT_PREORDER_SIGN_IN_COMPLETED_SESSION_KEY =
  "product-preorder-signin-clicked";
export const REVEAL_SIGN_IN_COMPLETED_SESSION_KEY = "reveal-signin-clicked";
export const TLP_FAVORITE_SIGN_IN_COMPLETED_SESSION_KEY =
  "tlp-favorite-signin-clicked";
export const ORDER_HISTORY_SIGN_IN_COMPLETED_SESSION_KEY =
  "order-history-signin-clicked";

/**
 * NOTE: these should be in sync with Tailwind's default screens
 */
export const SCREEN_SM = defaultTheme.screens.sm;
export const SCREEN_MD = defaultTheme.screens.md;
export const SCREEN_LG = defaultTheme.screens.lg;
export const SCREEN_XL = defaultTheme.screens.xl;
export const SCREEN_2XL = defaultTheme.screens["2xl"];
// Aliases for old breakpoints
export const LAPTOP_BREAKPOINT = parseInt(SCREEN_MD);
export const DESKTOP_BREAKPOINT = parseInt(SCREEN_LG);
export const XL_DESKTOP_BREAKPOINT = parseInt(SCREEN_XL);
export const XXL_DESKTOP_BREAKPOINT = 1690;

/*** Meta ***/

export const APP_META_TITLE = "Home";
export const APP_META_TITLE_TEMPLATE = ".SWOOSH";
export const APP_META_DESCRIPTION =
  ".SWOOSH is the home for Nike’s virtual creations.";
export const APP_META_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
export const APP_META_DEFAULT_IMAGE_URL = `${APP_META_SITE_URL}/images/og/default-og-image.png`;
export const APP_META_DEFAULT_IMAGE_ATTRIBUTES = {
  url: APP_META_DEFAULT_IMAGE_URL,
  width: 1200,
  height: 627,
  alt: "Nike .SWOOSH Logo",
};

/*** Misc ***/
export const ONBOARDING_LAUNCH_DATE = "11.18.2022";
