import * as React from "react";
import type { SVGProps } from "react";
const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.966 8.476 12 15.443 5.033 8.476"
    />
  </svg>
);
export default SvgCaretDown;
