import { ROUTE_PROFILE, ROUTE_SUBDOMAIN_PROFILE } from "@/constants/routes";
import { createRouteFromParams } from "./createRouteWithParams";
import { isDev, isProd } from "./environment";

/**
 * NOTE:
 *
 * We are using NextJS client routing for now.
 * In the future we will be required to use a subdomain to route to a user's profile.
 *
 * Example:
 *
 * `https://${swooshHandle}.${process.env.NEXT_PUBLIC_SITE_HOSTNAME}`
 * foo.swoosh.nike
 */

export const getUserProfileRoute = (swooshHandle: string) => {
  return createRouteFromParams(
    // isProd() ? ROUTE_SUBDOMAIN_PROFILE :
    ROUTE_PROFILE,
    { handle: swooshHandle },
  );
};
