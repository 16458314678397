import * as React from "react";
import type { SVGProps } from "react";
const SvgMonarch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M5 20.25h14M5.57 17.25 3.16 8.23c-.14-.49.47-.84.83-.48l4.45 4.45c.27.27.74.15.84-.22l2.24-8.35c.13-.49.83-.49.97 0l2.24 8.35c.1.37.56.5.84.22l4.45-4.45c.36-.36.97-.01.84.48l-2.42 9.02H5.57Z"
    />
  </svg>
);
export default SvgMonarch;
