import { PropsWithChildren } from "react";
import { HeadlineProps } from "../types";
import { getHeadlineStyles } from "../helpers";
import { PolymorphicComponent } from "@/src/components/PolymorphicComponent";

export function Headline({
  as = "h1",
  name,
  children,
  className,
  leading,
  md,
  ...props
}: PropsWithChildren<HeadlineProps>) {
  return (
    <PolymorphicComponent
      as={as}
      className={getHeadlineStyles({ className, name, md, leading })}
      {...props}
    >
      {children}
    </PolymorphicComponent>
  );
}
