import { useEffect } from "react";
import NProgress from "nprogress";
import { useRouter } from "next/navigation";

NProgress.configure({ showSpinner: false, trickleSpeed: 100 });

export function RouteProgress() {
  // const router = useRouter();
  // useEffect(
  //   function nprogressOnRouteChange() {
  //     router.events.on("routeChangeStart", NProgress.start);
  //     router.events.on("routeChangeError", NProgress.done);
  //     router.events.on("routeChangeComplete", NProgress.done);

  //     return () => {
  //       router.events.off("routeChangeStart", NProgress.start);
  //       router.events.off("routeChangeError", NProgress.done);
  //       router.events.off("routeChangeComplete", NProgress.done);

  //       NProgress.remove();
  //     };
  //   },
  //   [router.events],
  // );

  return null;
}
