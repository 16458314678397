import * as React from "react";
import type { SVGProps } from "react";
const SvgReport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.5 21.75h-9c-1.242 0-2.25-1.191-2.25-2.66V4.91c0-1.469 1.008-2.66 2.25-2.66h6.75l4.5 4.5v12.34c0 1.469-1.008 2.66-2.25 2.66Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18.75 6.75H15.5a1.5 1.5 0 0 1-1.5-1.5V2M16.25 12.5l-3 3-1.5-1.5L8 17.75"
    />
  </svg>
);
export default SvgReport;
