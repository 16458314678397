"use client";
import { OptimizelyFeatureFlags } from "@/src/services/optimizely/optimizelyFeatureFlags";
import { withOptimizely, WithOptimizelyProps } from "@optimizely/react-sdk";
import { useRouter } from "next/navigation";
import { ReactElement, useEffect, useState } from "react";
import { getDecisionFromOptimizelyDecisionsAndFlags } from "../services/optimizely/helpers";

type Props = {
  redirectTo?: string;
  optimizelyFlags: OptimizelyFeatureFlags[];
  children?: ReactElement;
  fallback?: ReactElement;
} & WithOptimizelyProps;

const OptimizelyFeatureFlaggedComponent: React.FC<Props> = ({
  redirectTo,
  optimizelyFlags,
  optimizely,
  children,
  fallback,
}) => {
  const [isConditionMet, setIsConditionMet] = useState<boolean>();

  const router = useRouter();
  const FallbackComponent = fallback ?? null;

  useEffect(() => {
    async function calculateOptimizely() {
      const decisions = await optimizely
        ?.onReady()
        .then(() => optimizely?.decideForKeys(optimizelyFlags as string[]));

      const conditionMet = getDecisionFromOptimizelyDecisionsAndFlags(
        optimizelyFlags,
        decisions,
      );

      setIsConditionMet(conditionMet);
      if (!conditionMet && redirectTo) {
        router.push(redirectTo);
      }
    }
    calculateOptimizely();
  }, [redirectTo, router, optimizely, optimizelyFlags]);

  if (
    !optimizely?.getIsReadyPromiseFulfilled() ||
    !optimizely?.isReady() ||
    // checking for undefined so that for a split second its not false
    isConditionMet === undefined
  )
    return null;

  return <>{isConditionMet ? children : FallbackComponent}</>;
};

export default withOptimizely(OptimizelyFeatureFlaggedComponent);
