import { ElementType } from "react";
import { PartialRecord, TypographyBreakpoint } from "../../types";

export enum BodyName {
  "Body_1" = "Body_1",
  "Body_2" = "Body_2",
  "Body_3" = "Body_3",
  "Body_4" = "Body_4",
  "Body_5" = "Body_5",
  "Body_6" = "Body_6",
  "Body_7" = "Body_7",
  "Body_8" = "Body_8",
  "Body_9" = "Body_9",
}
export type BodyNames = keyof typeof BodyName;
export type BodyProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>;
  name: BodyNames;
  className?: string;
  /**
   * For responsive typography you may set a Body name at a specified TypographyBreakpoint
   * Example:
   * <Body2 md={BodyName.Body_1} />
   */
} & PartialRecord<TypographyBreakpoint, BodyName>;
