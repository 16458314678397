import { useState } from "react";
import { Country } from "@/libs/geo/countries";
import CookieBanner from "@/src/components/GlobalElements/CookieWall/components/CookieBanner/CookieBanner";
import clsx from "clsx";

export type CookieBannerControllerType = ReturnType<typeof useCookieBanner>;

function useCookieBanner() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return { isOpen, setIsOpen };
}

type CookieBannerControllerProps = {
  country?: Country;
  className?: string;
  children: (controller: CookieBannerControllerType) => JSX.Element;
};

export function CookieBannerController(props: CookieBannerControllerProps) {
  const cookieBannerController = useCookieBanner();
  return (
    <>
      <CookieBanner
        country={props.country as Country}
        open={cookieBannerController.isOpen}
        setOpen={cookieBannerController.setIsOpen}
        className={clsx("theme-onboarding", props.className)} // @TODO: remove this theme
      />
      {props.children(cookieBannerController)}
    </>
  );
}
