import * as React from "react";
import type { SVGProps } from "react";
const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M12 6v6h5.25" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M20.25 12a8.25 8.25 0 1 1-16.5 0 8.25 8.25 0 1 1 16.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTime;
