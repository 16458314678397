import * as React from "react";
import type { SVGProps } from "react";
const SvgMemberRewards = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.5 17v4.25a.5.5 0 0 1-.596.484c-.096-.019-.254-.134-.254-.134L12 19l-3.65 2.6s-.158.115-.254.134a.499.499 0 0 1-.596-.484V14"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 15.75a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
    />
    <path
      fill="currentColor"
      d="M9.214 9.133c-.617-.457-.148-1.363.02-1.633-.303.334-.605.662-.836 1.022-.367.566-.527 1.19-.277 1.588.45.72 1.524.385 2.205.096l6.35-2.68-6.273 1.658c-.527.135-.951.122-1.19-.051Z"
    />
  </svg>
);
export default SvgMemberRewards;
