import { size } from "lodash";
import {
  CookiePreferences as ApiCookiePreferences,
  User as ApiUser,
} from "../schema/api/user";
import { CookiePreferences, User } from "../schema/user";

export const convertApiUserToUser = (
  apiUser: Partial<ApiUser>,
): Partial<User> => {
  return size(apiUser) > 0
    ? {
        ...apiUser,
        cookiePreferences: apiCookiePrefsToUserCookiePrefs(
          apiUser.cookiePreferences,
        ),
      }
    : {};
};

export const apiCookiePrefsToUserCookiePrefs = (
  apiCookiePreferences?: ApiCookiePreferences,
): CookiePreferences => {
  return apiCookiePreferences
    ? {
        performance: !!apiCookiePreferences?.performanceAgreedAt,
        functional: !!apiCookiePreferences.functionalAgreedAt,
      }
    : {};
};
