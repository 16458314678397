import clsx from "clsx";
import Link from "next/link";
import { FooterItemType } from "../../types";

const hoverStyles = "hover:text-green-500 active:text-green-500";

export function FooterItem(props: Omit<FooterItemType, "key">) {
  const className = clsx(hoverStyles, props.className);
  if (props.link) {
    return (
      <Link prefetch={false} className={className} href={props.link}>
        {props.title}
      </Link>
    );
  }
  if (props.onClick) {
    return (
      <button className={className} onClick={props.onClick}>
        {props.title}
      </button>
    );
  }

  return null;
}
