import * as React from "react";
import type { SVGProps } from "react";
const SvgCompare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M20 8.25H4.5M7.77 19.46l-3.71-3.71 3.71-3.71M16.23 4.54l3.71 3.71-3.71 3.71M4 15.75h15.5"
    />
  </svg>
);
export default SvgCompare;
