import * as React from "react";
import type { SVGProps } from "react";
const SvgImageNoImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 9a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m3.75 18.324 3.746-3.746 2.342 2.342 6.666-6.665 3.75 3.75"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h12A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgImageNoImage;
