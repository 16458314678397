import * as React from "react";
import type { SVGProps } from "react";
const SvgSort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m20.925 16.229-3.712 3.713-3.713-3.713M17.212 20V4.5M3.075 7.771l3.712-3.713L10.5 7.771M6.788 4v15.5"
    />
  </svg>
);
export default SvgSort;
