import { useUpdateCurrentUser } from "@/libs/apis/onboarding/useUpdateUser";
import { useCurrentUser } from "@/libs/apis/onboarding/useUser";
import { Country } from "@/libs/geo/countries";
import { CookiePreferences } from "@/libs/schema/user";
import { useLocalStorageValue } from "@react-hookz/web";
import { get, isEqual, size } from "lodash";
import { useEffect } from "react";
import { useCookieValue } from "@react-hookz/web/cjs/useCookieValue/useCookieValue";
import {
  convertToSQSettings,
  isCountryASupportedEMEACountry,
  isUserMFAed,
} from "../helpers";

const useSaveCookieSettings = (country: Country) => {
  const [cookieSettings, setCookieSettings] =
    useLocalStorageValue<CookiePreferences | null>("cookiePreferences");
  const [SQCookie, setSQCookie] = useCookieValue("sq");
  const updateCurrentUser = useUpdateCurrentUser();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const SQ = convertToSQSettings({
      allowMarketing: cookieSettings?.functional,
      allowPerformance: cookieSettings?.performance,
    });
    if (SQ !== undefined && isCountryASupportedEMEACountry(country))
      setSQCookie(`${SQ}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieSettings, country]);

  useEffect(() => {
    if (currentUser && isCountryASupportedEMEACountry(country)) {
      if (isUserMFAed(currentUser) && cookieSettings !== null) {
        if (!isEqual(cookieSettings, currentUser.cookiePreferences)) {
          updateCurrentUser.mutate({ cookiePreferences: cookieSettings });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieSettings, currentUser, country]);

  useEffect(() => {
    if (
      currentUser &&
      isUserMFAed(currentUser) &&
      !cookieSettings &&
      isCountryASupportedEMEACountry(country)
    ) {
      const userCookieSettings = get(currentUser, "cookiePreferences", null);
      if (size(userCookieSettings) > 0) {
        setCookieSettings(userCookieSettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, cookieSettings, country]);

  return { cookieSettings, setCookieSettings, SQCookie };
};

export default useSaveCookieSettings;
