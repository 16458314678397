import OnboardingSecondaryButton from "@/src/components/OnboardingSecondaryButton";
import React from "react";

import { mainCookieCopy, buttonCTAsForMainCookieView } from "../copy";

const cookieButtonStyles = "laptop:flex-1 py-2 uppercase";

type ButtonProps = { onClick: () => void };

const OnAcceptanceButton: React.FC<ButtonProps> = ({ onClick }) => (
  <OnboardingSecondaryButton
    style="inverse"
    buttonText={buttonCTAsForMainCookieView.accept}
    onClick={onClick}
    classes={cookieButtonStyles}
  />
);

const OnMoreInformationButton: React.FC<ButtonProps> = ({ onClick }) => (
  <OnboardingSecondaryButton
    onClick={onClick}
    buttonText={buttonCTAsForMainCookieView.moreInfo}
    classes={cookieButtonStyles}
  />
);

type Props = { onAccept: () => void; onMoreInfo: () => void };

const CookieAcceptView: React.FC<Props> = ({ onAccept, onMoreInfo }) => {
  return (
    <>
      {mainCookieCopy}
      <div className="flex w-full flex-row justify-center">
        <div className="laptop:my-5 laptop:h-auto laptop:flex-row flex basis-full flex-col-reverse justify-between gap-6 whitespace-nowrap">
          <OnMoreInformationButton onClick={onMoreInfo} />
          <OnAcceptanceButton onClick={onAccept} />
        </div>
      </div>
    </>
  );
};

export default CookieAcceptView;
