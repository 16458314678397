import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { GLOBAL_STATUS_BANNER } from "@/libs/apis/onboarding/reactQueryCacheKeys";
import { ERROR_CODES } from "@/libs/error/types";
import { makeGlobalStatusBannerApiCall } from "@/src/services/api/getGlobalStatusBanner";
import { GlobalStatusBanner } from "nvs-types/dist/types/generated/contentful";

type GlobalStatusBannerQueryData = GlobalStatusBanner;

export const useGlobalStatusBannerQuery = () => {
  return useQuery<
    GlobalStatusBannerQueryData,
    AxiosError,
    GlobalStatusBannerQueryData
  >([GLOBAL_STATUS_BANNER], () => makeGlobalStatusBannerApiCall(), {
    retry: false,
    useErrorBoundary: ({ response }) =>
      response !== undefined && response?.status >= ERROR_CODES.INTERNAL_ERROR,
  });
};
