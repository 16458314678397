import { Dialog } from "@headlessui/react";
import { noop, size } from "lodash";
import React, { useEffect } from "react";
import CookieBannerContent from "../CookieBannerContent";
import useSaveCookieSettings from "../../hooks/useSaveCookieSettings";
import { CookiePreferences } from "@/libs/schema/user";
import { Country } from "@/libs/geo/countries";
import { useCurrentUser } from "@/libs/apis/onboarding/useUser";
import BaseModal from "@/src/components/Modal/BaseModalTransition";
import ModalDivider from "@/src/components/Modal/ModalDivider";
import ModalTitle from "@/src/components/Modal/ModalTitle";
import clsx from "clsx";
import { isCountryASupportedEMEACountry } from "../../helpers";

type Props = {
  country: Country;
  open: boolean; // This prop is to allow user to change their preferences
  setOpen: (open: boolean) => void;
  className?: string;
};

const CookieBanner: React.FC<Props> = ({
  country,
  open,
  setOpen,
  className,
}) => {
  const { cookieSettings, setCookieSettings } = useSaveCookieSettings(country);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (cookieSettings === null && size(currentUser?.cookiePreferences) == 0) {
      if (isCountryASupportedEMEACountry(country)) {
        setOpen(true);
      }
    }
  }, [cookieSettings, country, currentUser, setOpen]);

  return (
    //Cannot close modal until preferences are selected, onClose is required
    <BaseModal
      isOpen={open}
      onClose={noop}
      className={clsx("z-cookieBanner bg-gray-950 text-cream", className)}
    >
      <div className="fixed inset-0 overflow-y-auto">
        <div className="laptop:justify-end laptop:p-3 flex min-h-full items-end justify-center text-center">
          <Dialog.Panel className="shadow-xls border-primary bg-gray-950 text-cream laptop:max-w-xl laptop:p-12 laptop:pb-7 flex w-full transform flex-col overflow-hidden border p-6 text-left align-middle transition-all">
            <ModalTitle classes="mb-5 text-xl font-medium uppercase text-cream">
              Your Cookie Settings
            </ModalTitle>
            <ModalDivider />
            <CookieBannerContent
              onAccept={(cookiePrefs: CookiePreferences) => {
                setCookieSettings(cookiePrefs);
                setOpen(false);
              }}
              cookieSettings={cookieSettings}
            />
          </Dialog.Panel>
        </div>
      </div>
    </BaseModal>
  );
};
export default CookieBanner;
