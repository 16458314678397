import * as React from "react";
import type { SVGProps } from "react";
const SvgShopNikeApp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M0 8.25h4.5M0 12.75h4.5M16.962 16.296a6.716 6.716 0 0 1-3.462.954 6.728 6.728 0 0 1-4.773-1.977A6.728 6.728 0 0 1 6.75 10.5c0-1.864.755-3.551 1.977-4.773A6.728 6.728 0 0 1 13.5 3.75c1.864 0 3.551.755 4.773 1.977A6.728 6.728 0 0 1 20.25 10.5a6.726 6.726 0 0 1-.921 3.407c-.517.882-.434 1.988.289 2.711l3.853 3.853"
    />
  </svg>
);
export default SvgShopNikeApp;
