import * as React from "react";
import type { SVGProps } from "react";
const SvgEllipsisVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.25 19.5a2.25 2.25 0 1 1-4.502-.002 2.25 2.25 0 0 1 4.502.002ZM14.25 12a2.25 2.25 0 1 1-4.502-.002A2.25 2.25 0 0 1 14.25 12ZM14.25 4.5a2.25 2.25 0 1 1-4.502-.002 2.25 2.25 0 0 1 4.502.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEllipsisVertical;
