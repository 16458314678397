import * as React from "react";
import type { SVGProps } from "react";
const SvgCards = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6 3.75h15A2.25 2.25 0 0 1 23.25 6v9"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.467 13.293c-.747-.548-.177-1.646.025-1.973-.364.402-.728.803-1.01 1.24-.447.685-.638 1.436-.336 1.919.541.868 1.84.466 2.668.117l7.686-3.242-7.595 2.005c-.636.166-1.147.144-1.438-.066"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9A2.25 2.25 0 0 1 4.5 6.75H18A2.25 2.25 0 0 1 20.25 9v7.5A2.25 2.25 0 0 1 18 18.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCards;
