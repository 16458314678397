import * as React from "react";
import type { SVGProps } from "react";
const SvgGiftCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.678 13.018c-.854-.627-.202-1.88.029-2.255-.417.459-.833.917-1.157 1.418-.51.782-.727 1.64-.383 2.19.618.995 2.104.536 3.05.136L18 10.8l-8.677 2.293c-.728.189-1.313.163-1.645-.076"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M2.25 16.5v-9A2.25 2.25 0 0 1 4.5 5.25h15a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGiftCard;
