import { ElementType } from "react";
import { PartialRecord, TypographyBreakpoint } from "../../types";

export enum HeadlineName {
  "Headline_1" = "Headline_1",
  "Headline_2" = "Headline_2",
  "Headline_3" = "Headline_3",
  "Headline_4" = "Headline_4",
  "Headline_5" = "Headline_5",
  "Headline_6" = "Headline_6",
}
export type HeadlineNames = keyof typeof HeadlineName;

export type HeadlineProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>;
  className?: string;
  name: HeadlineNames;
  /**
   * Optional leading override.
   * This should ONLY be used for special cases like <HighlightHeadline>.
   */
  leading?: string;
  /**
   * For responsive typography you may set a Headline name at a specified TypographyBreakpoint
   * Example:
   * <Headline2 md={HeadlineName.Headline_1} />
   */
} & PartialRecord<TypographyBreakpoint, HeadlineName>;
