import * as React from "react";
import type { SVGProps } from "react";
const SvgJournal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M11.5 3.75H5.75a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12.5a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H17"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6 6.75h5.5M6 9.75h4M10.75 21.75h4a2.498 2.498 0 0 0 2.5-2.5m0 0a2.46 2.46 0 0 0-.732-1.75 2.518 2.518 0 0 0-1.768-.75h-1V2.25h1a2.5 2.5 0 0 1 2.5 2.5v14.5Z"
    />
  </svg>
);
export default SvgJournal;
