import { ROUTE_HOME } from "@/constants/routes";
import { LinkButton } from "@/src/components/buttons/components/LinkButton";
import { Headline6 } from "@/src/components/typography/components/Headline";
import { HeadlineName } from "@/src/components/typography/components/Headline/types";

const NotFound = () => (
  <div className="h-fit pt-20 pb-20">
    <div className="flex h-full flex-col items-start justify-center md:items-center ">
      <div className="mt-10 ml-3 flex h-full flex-col justify-center gap-8 ">
        <Headline6
          md={HeadlineName.Headline_2}
          className="text-white"
          data-testid="404-error"
        >
          We can&apos;t find
          <br /> the page you&apos;re
          <br /> looking for
        </Headline6>

        <LinkButton href={ROUTE_HOME} className={"w-fit pr-16"}>
          Continue Exploring
        </LinkButton>
      </div>
    </div>
  </div>
);

export default NotFound;
