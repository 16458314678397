export const isDev = () => {
  return process.env.NEXT_PUBLIC_CURRENT_ENV === "development";
};

export const isProd = () => {
  return process.env.NEXT_PUBLIC_CURRENT_ENV === "production";
};

export const isTest = () => {
  return process.env.NEXT_PUBLIC_CURRENT_ENV === "test";
};