/***
 *
 * We can use this to create a singleton pattern. Unfortunately, next does not really support singletons.
 * They just get recreated for every page render. See https://github.com/vercel/next.js/discussions/15054
 *
 *
 ***/

export class GlobalRef<T> {
  private readonly sym: symbol;

  constructor(uniqueName: string) {
    this.sym = Symbol.for(uniqueName);
  }

  get value() {
    // @ts-ignore
    return (global as any)[this.sym] as T | undefined;
  }

  set value(value: T) {
    (global as any)[this.sym] = value;
  }
}
