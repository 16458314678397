import * as React from "react";
import type { SVGProps } from "react";
const SvgCaretLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.526 18.966 8.559 12l6.967-6.967"
    />
  </svg>
);
export default SvgCaretLeft;
