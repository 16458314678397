import * as React from "react";
import type { SVGProps } from "react";
const SvgMicrophone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M8.25 10.5a3.75 3.75 0 0 0 7.5 0V6a3.75 3.75 0 0 0-7.5 0v4.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M5.25 10.5a6.75 6.75 0 1 0 13.5 0M12 22.5v-6M9 21.75h6"
    />
  </svg>
);
export default SvgMicrophone;
