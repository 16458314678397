import React from "react";
import { DefaultSeo } from "next-seo";
import {
  APP_META_DESCRIPTION,
  APP_META_TITLE,
  APP_META_SITE_URL,
  APP_META_DEFAULT_IMAGE_ATTRIBUTES,
  APP_META_TITLE_TEMPLATE,
} from "@/constants/app";
import Head from "next/head";
import { isProd } from "@/src/helpers/environment";
import { usePathname } from "next/navigation";

/**
 * This set all default meta tags for the application.
 * If you need pages specific meta tags, use the `PageSEO` component.
 */

export const DocumentHeadDefaults: React.FC = () => {
  const pathName = usePathname();
  const url = `${APP_META_SITE_URL}${pathName}`;
  const isNotProd = !isProd();
  return (
    <>
      <DefaultSeo
        dangerouslySetAllPagesToNoFollow={isNotProd}
        dangerouslySetAllPagesToNoIndex={isNotProd}
        title={APP_META_TITLE}
        titleTemplate={`%s | ${APP_META_TITLE_TEMPLATE}`}
        description={APP_META_DESCRIPTION}
        canonical={url}
        openGraph={{
          images: [APP_META_DEFAULT_IMAGE_ATTRIBUTES],
        }}
      />
      <Head>
        <meta
          name="viewport"
          content="width=device-width,height=device-height,initial-scale=1.0"
        />
      </Head>
    </>
  );
};

export default DocumentHeadDefaults;
