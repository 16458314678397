import { useDecision } from "@optimizely/react-sdk";
import { OptimizelyFeatureFlags } from "./optimizelyFeatureFlags";
import { isNil } from "lodash";

export const LOADING = "loading";
/****************************************************************************
 * This hook returns two variables: enabled and whether the client is ready
 * It is important to check if the clientReady if there will be an
 * aggressive visual shift or there is an action that will be dispatched
 * upon truthiness.
 **************************************************************************/

const useIsFeatureFlagEnabled = (flag: OptimizelyFeatureFlags) => {
  const [decision, clientReady] = useDecision(flag, {
    autoUpdate: true,
  });

  return {
    enabled: decision.enabled,
    // optimizely is prematurely marking the client ready
    // it needs to wait for the userId to be set
    clientReady: clientReady && !isNil(decision.userContext.id),
  };
};

export default useIsFeatureFlagEnabled;
