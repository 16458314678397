import clsx from "clsx";
import { Country } from "@/libs/geo/countries";
import { rightFooterItems } from "../../../constants";
import { FooterItems } from "../../../types";
import { FooterItem } from "../FooterItem";
import { FooterTrademark } from "../FooterTrademark";
import { isCountryASupportedEMEACountry } from "../../../CookieWall/helpers";

type Props = {
  setOpenCookieBanner?: (boolean: boolean) => void;
  className?: string;
  country?: Country;
};

const itemClassName = clsx(
  "1440:basis-auto 1440:first:basis-auto", // desktop and above auto flex
  "md:basis-auto md:first:basis-full", // 768 first full flex, rest auto
  "flex basis-full pr-8 last:pr-0", // below 768 each has full flex
  "text-white uppercase",
);

export const RightFooter: React.FC<Props> = ({
  className,
  setOpenCookieBanner,
  country,
}) => {
  const showCookieSettings =
    setOpenCookieBanner && isCountryASupportedEMEACountry(country as Country);

  return (
    <div
      className={clsx(
        "1440:flex-nowrap flex-row flex-wrap",
        "flex basis-auto whitespace-nowrap text-sm uppercase text-white",
        className,
      )}
    >
      <FooterTrademark className={itemClassName} />
      {rightFooterItems.map((footerItem) => {
        const isCookieSetting = footerItem.key === FooterItems.COOKIE_SETTINGS;

        const onClick =
          showCookieSettings && isCookieSetting
            ? () => setOpenCookieBanner(true)
            : undefined;

        const isHidden = !showCookieSettings && isCookieSetting;

        if (isHidden) return null;

        return (
          <FooterItem
            {...footerItem}
            className={itemClassName}
            onClick={onClick}
            key={footerItem.key.toString()}
          />
        );
      })}
    </div>
  );
};

export default RightFooter;
