import { createContext, useContext, useEffect } from "react";
import { getGeoLocFromGeoCookie } from "@/src/helpers/middleware";
import { isProd } from "@/src/helpers/environment";
import Cookies from "js-cookie";
import { AkamaiGeoCookie } from "nvs-constants";
import { GEOLOC_COOKIE } from "./constants";

const initialGeoLoc = {
  country: !isProd() ? "US" : "", // need this here for pages w/o provider in layout
  region: "",
  timezone: "",
  latitude: 0,
  longitude: 0,
};

export const GeoLocContext = createContext<AkamaiGeoCookie>(initialGeoLoc);

export function useGeoLoc() {
  return useContext(GeoLocContext);
}

export const getGeoCookieFromCookies = (cookies: any) => {
  const geoCookieString = cookies && cookies[GEOLOC_COOKIE];
  return geoCookieString ?? undefined;
};

export function GeoLocProvider({ children }: { children: React.ReactNode }) {
  const cookies = Cookies.get();
  const geoCookie = getGeoCookieFromCookies(cookies);
  const geoLoc = getGeoLocFromGeoCookie(geoCookie);

  useEffect(() => {
    // On unmount, we have to remove the stored js-cookie instance of the geoloc
    // in order to accurately re-capture any new cookie settings on refresh

    // TO DO: remove this useEffect once we have ticket: NVS-3044 completed,
    // that will prevent folks from changing their cookie
    return () => Cookies.remove(GEOLOC_COOKIE);
  }, []);

  return (
    <GeoLocContext.Provider value={{ ...geoLoc }}>
      {children}
    </GeoLocContext.Provider>
  );
}
