import * as React from "react";
import type { SVGProps } from "react";
const SvgVr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m9.396 10.527 2.61 1.5 2.612-1.5M12.007 12.027v2.5M12.007 17.027v2.814M18.757 15.944l-2.442-1.417M5.257 15.944l2.442-1.417M12.007 7.341V4.527M5.257 8.424l2.442 1.417M18.757 8.424l-2.442 1.417M9.44 18.36l2.567 1.481 2.566-1.482M14.573 6.01l-2.566-1.483L9.439 6.01M16.19 17.427l2.566-1.482V12.98M18.757 11.388V8.424L16.19 6.942M7.824 17.427l-2.567-1.482V12.98M5.257 11.388V8.424l2.567-1.482"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M23.257 12.004c0 6.214-5.039 11.254-11.253 11.254C5.788 23.258.75 18.218.75 12.004.75 5.789 5.788.75 12.004.75c6.214 0 11.253 5.039 11.253 11.254Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVr;
