import * as React from "react";
import type { SVGProps } from "react";
const SvgBookmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7.5 3.75h9A2.25 2.25 0 0 1 18.75 6v13.499a.75.75 0 0 1-1.28.53L12 14.559l-5.47 5.47a.75.75 0 0 1-1.28-.53V6A2.25 2.25 0 0 1 7.5 3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBookmark;
