import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import * as React from "react";

type Props = React.PropsWithChildren<{
  classes?: string;
}>;

const ModalTitle: React.FC<Props> = ({ classes, children }) => (
  <div className="flex flex-col justify-around">
    <Dialog.Title
      as="h3"
      className={clsx("flex flex-row items-center justify-between", classes)}
    >
      {children}
    </Dialog.Title>
  </div>
);

export default ModalTitle;
