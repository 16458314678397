import { ElementType } from "react";
import { PartialRecord, TypographyBreakpoint } from "../../types";

export enum TitleName {
  "Title_1" = "Title_1",
  "Title_2" = "Title_2",
  "Title_3" = "Title_3",
  "Title_4" = "Title_4",
  "Title_5" = "Title_5",
  "Title_6" = "Title_6",
  "Title_7" = "Title_7",
}
export type TitleNames = keyof typeof TitleName;
export type TitleProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>;
  name: TitleNames;
  className?: string;
  /**
   * For responsive typography you may set a Title name at a specified TypographyBreakpoint
   * Example:
   * <Title2 md={TitleName.Title_1} />
   */
} & PartialRecord<TypographyBreakpoint, TitleName>;
