import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { useState } from "react";
import { useGeoLoc } from "@/libs/geo/GeoLocProvider";
import {
  OPTIMIZELY_REFRESH_INTERVAL,
  UNAUTHENTICATED_OPTIMIZELY_USER,
} from "./constants";
import { getSession } from "next-auth/react";
import { getOptimizelyLogLevel } from "./helpers";

type Props = React.PropsWithChildren<{
  dataFromOptimizely?: string | object;
  upmId?: null | string;
}>;

const OptimizelyProviderWrapper: React.FC<Props> = ({
  dataFromOptimizely,
  children,
}) => {
  const { country } = useGeoLoc();

  const [optimizelyClient] = useState(() => {
    return createInstance({
      sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
      datafile: dataFromOptimizely,
      logLevel: getOptimizelyLogLevel(),
      datafileOptions: {
        autoUpdate: true,
        updateInterval: OPTIMIZELY_REFRESH_INTERVAL,
      },
      odpOptions: {
        disabled: true,
      },
    });
  });

  return (
    <OptimizelyProvider
      timeout={3000}
      optimizely={optimizelyClient}
      user={getOptimizelyUserInfo(country).then((user) => user)}
    >
      {children}
    </OptimizelyProvider>
  );
};

const getOptimizelyUserInfo = async (country?: string) => {
  const session = await getSession();
  const upmIdToPass = session?.user.upmId;

  const userInfoObject = {
    id: upmIdToPass ?? UNAUTHENTICATED_OPTIMIZELY_USER,
    attributes: {
      upmId: upmIdToPass ?? UNAUTHENTICATED_OPTIMIZELY_USER,
      geoloc_cookie: country ?? "",
    },
  };

  return userInfoObject;
};

export default OptimizelyProviderWrapper;
