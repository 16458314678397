import * as React from "react";
import type { SVGProps } from "react";
const SvgCoach = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M3.75 21v-3c0-2.07 1.68-3.75 3.75-3.75h9c2.07 0 3.75 1.68 3.75 3.75v3M8.25 6.75H19.5M12 11.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
    />
  </svg>
);
export default SvgCoach;
